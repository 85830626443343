import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { BuilderSettings } from '../../redux/types/builders';
import { useEffect } from 'react';

interface DisclosureProps {
  formik: any;
  builderSettings: BuilderSettings;
}

export const Disclosure = ({ formik, builderSettings }: DisclosureProps) => {
  const companyName = builderSettings?.legal_name || builderSettings?.webflow_name || builderSettings?.builderName;

  useEffect(() => {
    const formikFieldActive = formik.getFieldProps('customDisclosureActive')?.value ?? false;

    if (builderSettings?.custom_disclosure_active && !formikFieldActive) {
      formik.setFieldValue('customDisclosureActive', true);
    }
  }, [builderSettings?.custom_disclosure_active, formik]);

  const defaultDisclosureModal = () => {
    Swal.fire({
      title: 'Visitor Access Agreement',
      text: `Customer agrees that any entry onto the property is at the Customer’s own risk. Customer hereby waives any and all claims against ${companyName} Inc., its affiliates, subsidiaries, owners, officers, directors, managers, employees, representatives or agents (collectively, “${companyName}”) for injury or damage to person or property arising from, or related to, Customer’s entry onto the property. Customer will defend, indemnify and hold ${companyName} harmless against any injury, loss, damage or expense to person or property arising from, or related to, the acts or omissions of Customer, or of any person accompanying Customer onto the property. Customer will act in accordance with all applicable laws while on the property. Customer will not provide any false information or identification. Customer will be responsible for all damage to furniture, fixtures and equipment caused, directly or indirectly, by Customer.`,
      confirmButtonColor: builderSettings.primary_color,
      confirmButtonText: 'Accept',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        formik.setFieldValue('privacyPolicy', true);
      }

      if (result.isDismissed) {
        formik.setFieldValue('privacyPolicy', false);
      }
    });
  };

  const customDisclosureModal = () => {
    Swal.fire({
      title: builderSettings.custom_disclosure_name,
      text: builderSettings.custom_disclosure_text,
      confirmButtonColor: builderSettings.primary_color,
      confirmButtonText: 'Accept',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        formik.setFieldValue('customDisclosure', true);
      }

      if (result.isDismissed) {
        formik.setFieldValue('customDisclosure', false);
      }
    });
  };

  return (
    <Form.Group
      className={
        formik.errors.privacyPolicy || formik.errors.customDisclosure
          ? 'form-group form-group-checkbox mt-0 is-invalid'
          : 'form-group form-group-checkbox mt-0'
      }
    >
      <Form.Check
        type="checkbox"
        label={
          <small>
            I have read and accept NterNow’s{' '}
            <button
              type="button"
              className="link-button"
              onClick={() => {
                defaultDisclosureModal();
              }}
            >
              Visitor Access Agreement
            </button>
            {', '}
            <a
              href="https://nternow.com/privacy-policy/"
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href="https://nternow.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Terms of service
            </a>
            .
          </small>
        }
        id="privacyPolicy"
        checked={formik.values.privacyPolicy}
        isInvalid={!!formik.errors.privacyPolicy}
        onChange={() =>
          formik.setFieldValue('privacyPolicy', !formik.values.privacyPolicy)
        }
      />
      {builderSettings?.custom_disclosure_active && (
        <Form.Check
          type="checkbox"
          label={
            <small>
              <button
                type="button"
                className="link-button"
                onClick={() => {
                  customDisclosureModal();
                }}
              >
                {builderSettings.custom_disclosure_name}
              </button>
              .
            </small>
          }
          id="customDisclosure"
          checked={formik.values.customDisclosure}
          isInvalid={!!formik.errors.customDisclosure}
          onChange={() =>
            formik.setFieldValue('customDisclosure', !formik.values.customDisclosure)
          }
        />
      )}
      <span className="invalid-feedback">
        {formik.errors.privacyPolicy || formik.errors.customDisclosure
          ? 'You must accept our terms and conditions to continue'
          : ''}
      </span>
    </Form.Group>
  )
}