import { useSelector } from 'react-redux';
import { selectBuilderSettings } from '../../redux/selectors/builders';
import defaultLogo from '../../assets/images/default-logo.svg';
import { Link } from 'react-router-dom';

const Header = () => {
  const builderSettings = useSelector(selectBuilderSettings);
  const builderName = localStorage.getItem('builder_name');

  return (
    <header>
      <div className="brand-logo">
        <Link to={`/${builderName}`}>
          <img src={builderSettings?.logo || defaultLogo} alt="header-logo" />
        </Link>
      </div>
    </header>
  );
};

export default Header;
