import { useHistory, useLocation, useParams } from 'react-router-dom';
import { USER_TOKEN_KEY, frontendRoutes } from '../../../Constants/constants';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { getBuilderId, getScheduledTours } from "../../../redux/action/action";
import Header from '../../Header/Header';
import CustomTabs from '../../Tabs/Tabs';
import TourCard from '../../TourCard/TourCard';
import Footer from '../../Footer/Footer';
import jwt_decode from "jwt-decode";
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import ReactPaginate from 'react-paginate';

interface tokenPayload {
  customerId: number;
  username: string;
  iat: number;
  exp: number;
}

export interface scheduledToursResponse {
  address_1: string
  bedrooms: number
  builder: string
  builder_id: number
  cell_phone_1: string
  city: string
  contract_date: null
  created_on: string
  customer_id: number
  did_send_code: number
  did_send_reminder: number
  email_1: string
  firstname: string
  fullBathrooms: number
  halfBathrooms: number
  house_num: string
  id: number
  lastname: string
  latitude: number
  longitude: number
  price: number
  property_id: number
  remarks: null
  scheduled_on: string
  squareFootage: number
  state: string
  status: string
  timezone: number
  url_1: string
  zipcode: string
  images: string[]
}

const UpcomingTours = () => {
    const token = localStorage.getItem(USER_TOKEN_KEY);
  
    const params = useParams<{ builder_name?: string }>();
    const history = useHistory();
    const dispatch = useDispatch()
    const location = useLocation()

    const builderId = useSelector((state: any) => state.builder.builder_id);
    const loadingTours = useSelector((state: any) => state.scheduleTours.loading);
    const scheduledTours = useSelector((state: any) => state.scheduleTours.scheduledTours);
    
    const [page, setPage] = useState(0)

    const tabs = [
      {
        name: 'upcoming',
        url: frontendRoutes.upcomingTours(params.builder_name).path
      },
      {
        name: 'previous',
        url: frontendRoutes.previousTours(params.builder_name).path
      }
    ]

    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const page: any = queryParams.get('page') || 1
      setPage(page)
    }, [])

    useEffect(() => {
      page !== 0 && getScheduledVisits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])
    
    useEffect(() => {
        !builderId && dispatch(getBuilderId());
    }, [builderId, dispatch])

    const getScheduledVisits = () => {
      if (token) {
        let decodedToken: tokenPayload = jwt_decode(token);
        dispatch(getScheduledTours(decodedToken.customerId, page))
      }
    }

    const handlePageClick = (page: any) => {
      setPage(page.selected + 1)
      history.push(`${location.pathname}?page=${page.selected + 1}`)
    }

  return (
    <section className="main-section upcoming-tours-screen d-flex justify-content-center">
      <div className="box">
        <Header />
        <div className="main-title">
          <h1>My Tours</h1>
        </div>
        <span className="link-back icn icn-back-arrow" onClick={() => history.goBack()}></span>

        <div className="box-data d-flex flex-column mt-4">
          <CustomTabs 
            activeTab={tabs[0].name}
            allTabs={tabs} 
          />
          {loadingTours ? <LoadingSpinner /> : <div className='tour-cards-box pt-3'>
            {scheduledTours?.data?.length ? scheduledTours?.data?.map((data: scheduledToursResponse, index: number) => 
              <TourCard
                key={index}
                tour={data}
              />
            ) : <p className='mt-3 text-center'>No Upcoming Tours</p>}
          </div>}

          {scheduledTours?.count > 10 &&
            <div className='pagination mt-5'>
              <ReactPaginate
                breakLabel="..."
                nextLabel="&#10095;"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={scheduledTours?.count / 10}
                forcePage={page - 1}
                previousLabel="&#10094;"
                renderOnZeroPageCount={null}
              />
          </div>
          }
          
        </div>
        <Footer />
      </div>
    </section>
  )
}

export default UpcomingTours