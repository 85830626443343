import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { CONTENT_SETTINGS_DEFAULT, BuilderContentSettings } from '../Constants/content';

export const useContentSettings = () => {
  const content_settings = useSelector((state: any) => JSON.parse(state.builder?.settings?.content_settings || '{}')) as BuilderContentSettings;

  return useCallback((key: keyof BuilderContentSettings) => {
    return content_settings[key] || CONTENT_SETTINGS_DEFAULT[key];
  }, [content_settings]);
}
