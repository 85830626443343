/* eslint-disable import/no-anonymous-default-export */
import { applyMiddleware, createStore } from "redux";

// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";

import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { rootReducer } from "./reducer/index";

export type AppState = ReturnType<typeof rootReducer>;

const middleware = [thunk];

// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: ["community", "user", "register", "searchCommunity", 'property', 'tour', 'phNumber', "fields"],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  // let persistor = persistStore(store);
  return { store /* , persistor */ };
};
