import Footer from '../../Footer/Footer';
import { useHistory, useParams } from 'react-router-dom';
import { frontendRoutes } from '../../../Constants/constants';
import { Button } from '../../Button/Button';
import Header from '../../Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getBuilderId } from '../../../redux/action/action';
import { BuilderSettings } from '../../../redux/types/builders';
import { useContentSettings } from '../../../hooks/useContentSettings';

const HowitWork = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useContentSettings();

  const previousPath = () => {
    history.goBack();
  };
  let params = useParams<{ builder_name?: string }>();
  const builderId = useSelector((state: any) => state.builder.builder_id);
  const builderSettings: BuilderSettings = useSelector((state: any) => state.builder.settings);
  const isBuilderSettings = Object.keys(builderSettings).length > 0;

  useEffect(() => {
    if (!builderId) {
      dispatch(getBuilderId());
    }
  }, [dispatch, builderId]);

  return (
    <section className="main-section work-screen d-flex justify-content-center">
      <div className="box">
        <Header />
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <h1 className="title">{t('self_tours_own_time_title')}</h1>
        <div className="box-data d-flex flex-column">
          <ul className="work-item">
            <li className="d-flex align-items-center">
              <span
                className="custom-item"
                style={{
                  background: isBuilderSettings ? builderSettings.primary_color : '',
                  flexShrink: 0,
                }}
              >
                1
              </span>
              <div>
                <p className="sub-title">{t('schedule_tour_title')}</p>
                <p className="">
                  {t('schedule_tour_description')}
                </p>
              </div>
            </li>
            <li className="d-flex align-items-center">
              <span
                className="custom-item"
                style={{
                  background: isBuilderSettings ? builderSettings.primary_color : '',
                  flexShrink: 0,
                }}
              >
                2
              </span>
              <div>
                <p className="sub-title">{t('tour_now_title')}</p>
                <p className="">
                  {t('tour_now_description')}
                </p>
              </div>
            </li>
          </ul>
          <div className="box-footer mt-auto">
            <Button
              className="button w-100"
              onClick={() => {
                history.push(frontendRoutes.chooseTour(params.builder_name).path);
              }}
            >
              Let's Get Started
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default HowitWork;
