import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Footer from '../../Footer/Footer';
import { frontendRoutes } from '../../../Constants/constants';
import { selectBuilderSettings } from '../../../redux/selectors/builders';
import defaultLogo from '../../../assets/images/default-logo.svg';
import { useEffect } from 'react';
import { getBuilderId } from '../../../redux/action/action';

const TourPrefereces = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ builder_name?: string }>();
  const builderSettings = useSelector(selectBuilderSettings);
  const communityId = useSelector((state: any) => state?.searchCommunity?.search?.choose?.community_id);
  const propertyId = useSelector((state: any) => state?.property?.property?.id);
  const builderId = useSelector((state: any) => state.builder.builder_id);

  useEffect(() => {
    verifyPreferTour()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  useEffect(() => {
    if (!builderId) {
      dispatch(getBuilderId());
    }
  }, [dispatch, builderId]);

  const previousPath = () => {
    history.goBack();
  };

  const verifyPreferTour = () => {
    if (!communityId && !propertyId) {
      history.push(frontendRoutes.chooseCommunity(params.builder_name).path);
    } else if (propertyId && !communityId) {
      history.push(frontendRoutes.selectTourDate(params.builder_name, propertyId).path);
    }
  }

  return (
    <section className="main-section tour-screen d-flex justify-content-center">
      <div className="box">
        <div className="main-title">
          <figure>
            <img src={builderSettings.logo || defaultLogo} alt="Fairfield" />
          </figure>
          <span className="text-blue">
            <sup>*</sup>required
          </span>
        </div>
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <div className="box-data d-flex flex-column">
          <h1>How do you want to tour?</h1>
          <div
            className="link-item"
            onClick={() => {
              history.push(frontendRoutes.selectTourDate(params.builder_name, propertyId).path);
            }}
          >
            <i className="icn icn-person"></i>
            <h2>In Person Tour</h2>
            <small>Schedule in person self tour!</small>
            <i className="icn icn-back-arrow"></i>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default TourPrefereces;
