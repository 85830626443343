import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Footer from '../../Footer/Footer';
import { selectBuilderSettings } from '../../../redux/selectors/builders';
import defaultLogo from '../../../assets/images/default-logo.svg';
import { useEffect, useState } from 'react';
import { getBuilderId } from '../../../redux/action/action';
import { Button } from '../../Button/Button';
import {
  cancelVisits
} from '../../../redux/action/action';
import Loader from '../../Loader/Loader';
import { CancelTourSuccess } from '../SuccessPage/CancelTourSuccess';



const TourCancellation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const builderSettings = useSelector(selectBuilderSettings);
  const builderId = useSelector((state: any) => state.builder.builder_id);
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [success,showSuccess] = useState(false)
  const [message,setMessage] = useState<any>(null)

  useEffect(() => {
    if (!builderId) {
      dispatch(getBuilderId());
    }
  }, [dispatch, builderId]);

  const previousPath = () => {
    history.goBack();
  };

  const cancelTour=async ()=>{
    const data={
      scheduledVisitId: queryParams.get('scheduledVisitId'),
    }
    const result:any = await dispatch(cancelVisits(data,setIsLoading));
    showSuccess(true)
    if(result?.data?.success) {
      setMessage({isSuccess:true,message:result.data.message})
    } else {
      setMessage({isSuccess:false,message:"Tour not found"})
    }
  }

  return (
    <section className="main-section tour-screen d-flex justify-content-center">
      <div className="box">
      {success ? <CancelTourSuccess message={message}/> : <div className="main-title">
          <figure>
            <img src={builderSettings.logo || defaultLogo} alt="Fairfield" />
          </figure>
        </div>}

       {!success && <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>}
        <div className="box-data d-flex flex-column justify-content-center text-center">
          {isLoading && <Loader />}
          <h1 className='mb-1'>Would you like to Cancel the Tour ?</h1>
            <Button
            className="button w-100 mt-4"
            onClick={cancelTour}
          >
            Cancel Tour
          </Button>
          </div>  
        <Footer />
      </div>
    </section>
  );
};

export default TourCancellation;
