import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { /* checkOS, */ frontendRoutes } from '../../../../Constants/constants';
import { Button } from '../../../Button/Button';
import locationIcon from '../../../../assets/images/icn-location.svg';

const ConfirmScreen = () => {
  const history = useHistory();
  const previousPath = () => {
    history.goBack();
  };
  let params = useParams<{ builder_name?: string }>();
  const tourDate = useSelector((state: any) => state.tour.tour);
  const propertyId = useSelector((state: any) => state.property.property?.id);
  const propertyAddress = useSelector((state: any) => state?.property?.property?.address);
  const communityName = useSelector((state: any) => state?.property?.property?.communityName);

  return (
    <section className="main-section success-screen tour-success-screen d-flex justify-content-center">
      <div className="box">
        <div className="status-title success">
          <h1>SUCCESS!</h1>
          <span>
            Your tour is successfully scheduled: {tourDate && tourDate.showDate}, at{' '}
            {tourDate && tourDate.customTime}
          </span>
          <div className="tour-address d-flex align-items-start gap-2 my-2">
            <img src={locationIcon} alt="location-icon" />
            <p>
              {propertyAddress} {communityName && `in ${communityName}`}
            </p>
          </div>
          <div className="d-flex w-100">
            <span onClick={previousPath} className="a">
              Back
            </span>
            <Link
              to={frontendRoutes.selectTourDate(params.builder_name, propertyId).path}
              className=""
            >
              Reschedule
            </Link>
            <Link to={frontendRoutes.chooseCommunity(params.builder_name).path} className="">
              Start Over
            </Link>
          </div>
        </div>
        <div className="box-data d-flex flex-column text-center">
          <div className="my-auto text-center">
            <i className="icn icn-home"></i>
            <p>You will receive a confirmation email with your scheduled tour.</p>
          </div>
          <div className="box-footer">
            {/* <Button onClick={checkOS} className="button w-100">
              Download NterNow
            </Button> */}
            <Button
              onClick={() => {
                history.push(frontendRoutes.chooseCommunity(params.builder_name).path);
              }}
              className="button w-100"
            >
              Find other properties
            </Button>
            {/* <p>Please download the app to continue browsing properties</p> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmScreen;
