import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { getBuilderId } from "../../../redux/action/action";
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Button } from '../../Button/Button';
import { FormikProvider, useFormik } from 'formik';
import { Form, Spinner } from 'react-bootstrap';
import { updateProfileValidation } from '../../../Constants/validations';
import jwt_decode from 'jwt-decode';
import { USER_TOKEN_KEY, backendRoutes } from '../../../Constants/constants';
import axios from 'axios';
import { GET_USER_INFO_SUCCESS } from '../../../redux/types/actionTypes';

interface tokenPayload {
  customerId: number;
  username: string;
  iat: number;
  exp: number;
}

const EditProfile = () => {
    const token = localStorage.getItem(USER_TOKEN_KEY);

    const history = useHistory();
    const dispatch = useDispatch()
    const builderId = useSelector((state: any) => state.builder.builder_id);
    const userInfo = useSelector((state: any) => state.user.userInfo);

    const [firstNameFocused, setFirstNameFocused] = useState(false)
    const [lastNameFocused, setLastNameFocused] = useState(false)
    const [emailFocused, setEmailFocused] = useState(false);
    const [currentPassFocused, setCurrentPassFocused] = useState(false);
    const [newPassFocused, setNewPassFocused] = useState(false);
    const [confirmNewPassFocused, setConfirmNewPassFocused] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateMessage, setUpdateMessage] = useState({
      isError: false,
      message: ''
    })

    const formik = useFormik({
        initialValues: {
          firstName: userInfo?.firstname,
          lastName: userInfo?.lastname,
          email: userInfo?.email_1,
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: ''
        },
        enableReinitialize: true,
        validationSchema: updateProfileValidation,
        onSubmit: async values => {
          setIsUpdating(true)
          try {
            const payload = {
            password: values.currentPassword,
            newPassword: values.newPassword,
            firstname: values.firstName,
            lastname: values.lastName,
            email_1: values.email
          }

          if (token) {
            let { customerId }: tokenPayload = jwt_decode(token);
            const config = {
              headers: {
                "x-auth-token": token
              },
            }
            const { data } =  await axios.patch(`${backendRoutes.MOBILE_BASE_URL}customers/details/${customerId}`, payload, config)
            if (data?.data) {
              dispatch({
                type: GET_USER_INFO_SUCCESS,
                payload: data?.data,
              });     
              setUpdateMessage({isError: false, message: data?.message})
            }
        }
          } catch (error) {
            console.log(error);
            setUpdateMessage({isError: true, message: 'Invalid customer or password.'})
          }
        setIsUpdating(false)
        },
      });

    useEffect(() => {
        !builderId && dispatch(getBuilderId());
    }, [builderId, dispatch])

    useEffect(() => {
      if (updateMessage.message) {
        setTimeout(() => {
          setUpdateMessage({isError: false, message: ''});
        }, 5000);
      }
    }, [updateMessage])

  return (
    <section className="main-section upcoming-tours-screen d-flex justify-content-center">
      <div className="box">
        <Header />
        <div className="main-title">
          <h1>Edit Profile</h1>
          {updateMessage.message && <p className={`${updateMessage.isError ? 'text-danger' : 'text-success'} mt-2`}>{updateMessage.message}</p>}
        </div>
        <span className="link-back icn icn-back-arrow" onClick={() => history.goBack()}></span>

        <div className="box-data d-flex flex-column mt-4">
            <Form
                className="material-form d-flex flex-column h-100"
                onSubmit={formik.handleSubmit}
                >
                <FormikProvider value={formik}>
                    <Form.Group
                        className={`${ firstNameFocused === false && formik.values.firstName === ''
                        ? 'form-group'
                        : 'form-group input-focus'}`}
                        controlId="firstName"
                    >
                        <Form.Control
                        type="text"
                        name="firstName"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        isInvalid={formik.touched.firstName && !!formik.errors.firstName}
                        onFocus={() => setFirstNameFocused(true)}
                        onBlur={e => {
                            setFirstNameFocused(false);
                            formik.handleBlur(e);
                        }}
                        />
                        <Form.Label>First Name</Form.Label>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.firstName}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className={`${ lastNameFocused === false && formik.values.lastName === ''
                        ? 'form-group'
                        : 'form-group input-focus'}`}
                        controlId="lastName"
                    >
                        <Form.Control
                        type="text"
                        name="lastName"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        isInvalid={formik.touched.lastName && !!formik.errors.lastName}
                        onFocus={() => setLastNameFocused(true)}
                        onBlur={e => {
                            setLastNameFocused(false);
                            formik.handleBlur(e);
                        }}
                        />
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.lastName}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className={`${ emailFocused === false && formik.values.email === ''
                        ? 'form-group'
                        : 'form-group input-focus'}`}
                        controlId="email"
                    >
                        <Form.Control
                        type="text"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        isInvalid={formik.touched.email && !!formik.errors.email}
                        onFocus={() => setEmailFocused(true)}
                        onBlur={e => {
                            setEmailFocused(false);
                            formik.handleBlur(e);
                        }}
                        />
                        <Form.Label>Email</Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className={`${ currentPassFocused === false && formik.values.currentPassword === ''
                        ? 'form-group'
                        : 'form-group input-focus'}`}
                        controlId="currentPassword"
                        >
                        <Form.Control
                            type={'password'}
                            name="currentPassword"
                            onChange={formik.handleChange}
                            value={formik.values.currentPassword}
                            isInvalid={formik.touched.currentPassword && !!formik.errors.currentPassword}
                            onFocus={() => setCurrentPassFocused(true)}
                            onBlur={e => {
                            setCurrentPassFocused(false);
                            formik.handleBlur(e);
                            }}
                        />
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.currentPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                    className={`${ newPassFocused === false && formik.values.newPassword === ''
                    ? 'form-group'
                    : 'form-group input-focus'}`}
                    controlId="newPassword"
                    >
                    <Form.Control
                        type={'password'}
                        name="newPassword"
                        onChange={formik.handleChange}
                        value={formik.values.newPassword}
                        isInvalid={formik.touched.newPassword && !!formik.errors.newPassword}
                        onFocus={() => setNewPassFocused(true)}
                        onBlur={e => {
                        setNewPassFocused(false);
                        formik.handleBlur(e);
                        }}
                    />
                    <Form.Label>New Password</Form.Label>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.newPassword}
                    </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                    className={`${ confirmNewPassFocused === false && formik.values.confirmNewPassword === ''
                    ? 'form-group'
                    : 'form-group input-focus'}`}
                    controlId="confirmNewPassword"
                    >
                    <Form.Control
                        type={'password'}
                        name="confirmNewPassword"
                        onChange={formik.handleChange}
                        value={formik.values.confirmNewPassword}
                        isInvalid={formik.touched.confirmNewPassword && !!formik.errors.confirmNewPassword}
                        onFocus={() => setConfirmNewPassFocused(true)}
                        onBlur={e => {
                        setConfirmNewPassFocused(false);
                        formik.handleBlur(e);
                        }}
                        disabled={!formik.values.currentPassword && !formik.values.newPassword}
                    />
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.confirmNewPassword}
                    </Form.Control.Feedback>
                    </Form.Group>

                    <div className="box-footer mt-auto">
                    
                    <Button className="button w-100 d-flex justify-content-center align-items-center spinner-btn" type={isUpdating ? "button" : "submit"}>
                      {isUpdating ? <Spinner animation="border" /> : 'Update Profile'}
                    </Button>
                    </div>
                </FormikProvider>
            </Form>
        </div>
        <Footer />
      </div>
    </section>
  )
}

export default EditProfile