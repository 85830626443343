import Footer from '../../Footer/Footer';
import { Link, useHistory, useParams } from 'react-router-dom';
import { checkOS, frontendRoutes } from '../../../Constants/constants';
import { convertDistance, getDistance } from 'geolib';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button } from '../../Button/Button';
import { getBuilderId } from '../../../redux/action/action';
import { ReactComponent as BackIcon } from '../../../assets/images/icn-arrow-prev.svg';

const ShareLocation = () => {
  const [locationError, setLocationError] = useState(false);
  const [distance, setDistance] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ builder_name?: string }>();
  const property = useSelector((state: any) => state.property.property);
  const propertyId = useSelector((state: any) => state.property.property?.id);
  const builderId = useSelector((state: any) => state.builder.builder_id);

  useEffect(() => {
    if (!builderId) {
      dispatch(getBuilderId());
    }
  }, [dispatch, builderId]);

  const handleGetPosition = ({ coords }: { coords: { latitude: number; longitude: number } }) => {
    const currentPosition = {
      latitude: coords.latitude,
      longitude: coords.longitude,
    };
    const propertyPosition = {
      latitude: property.latitude,
      longitude: property.longitude,
    };
    const distance = getDistance(currentPosition, propertyPosition);
    const distanceInMiles = convertDistance(distance, 'mi');

    if (distanceInMiles > 8) {
      setDistance(distanceInMiles);
      return;
    }

    history.push(frontendRoutes.LockCodeScreen(params.builder_name).path);
  };

  const onAccept = (event: any) => {
    event.preventDefault();
    navigator.geolocation.getCurrentPosition(
      handleGetPosition,
      error => {
        setLocationError(true);
        console.log(error);
      },
      {
        enableHighAccuracy: true,
      },
    );
  };

  if (distance > 8) {
    return (
      <section className="main-section d-flex justify-content-center">
        <div className="box">
          <div className="status-title error">
            <h1>Uh-Oh</h1>
            <span>It appears that you have not yet arrived at your destination.</span>
            <div>
              <span onClick={() => history.goBack()} className="a">
                Back
              </span>
              <Link to={frontendRoutes.selectTourDate(params.builder_name, propertyId).path}>
                Reschedule
              </Link>
              <Link to={frontendRoutes.chooseCommunity(params.builder_name).path}>Start Over</Link>
            </div>
          </div>
          <h2 className="title">You’re just a short {Math.ceil(distance)} miles away!</h2>
          <div className="text-container">
            <p className="text">
              Please click the button below when you arrive on-site to continue your self-tour
              experience, or if you wish to continue through our mobile app,{' '}
              <button onClick={checkOS} className="link-button">
                click here.{' '}
              </button>
            </p>
          </div>
          <a href="tel:+1-844-531-1283" className="need-help-link" target="_blank" rel="noreferrer">
            Need Help? <i className="icn icn-phone"></i>
          </a>
          <div style={{ padding: '96px 40px 60px 40px' }}>
            <Button
              onClick={() => {
                history.push(frontendRoutes.selectTourDate(params.builder_name, propertyId).path);
              }}
              className="button back-button mb-5"
            >
              <BackIcon />
              Back
            </Button>
            <p className="text">
              To make the most of the NterNow experience, please download the mobile app. For more
              questions:{' '}
              <a
                href="https://nternow.com/self-touring-landing-page/"
                target="_blank"
                rel="noreferrer noopener"
              >
                FAQ
              </a>
              .
            </p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="main-section location-screen d-flex justify-content-center">
      <div className="box">
        <div className="main-title"></div>
        <span onClick={() => history.goBack()} className="link-back icn icn-back-arrow"></span>

        <div className="box-data d-flex flex-column text-center">
          <i className={`icn ${locationError ? 'icn-error-pin' : 'icn-pin'}`}></i>
          {locationError ? (
            <>
              <h1 className="h2">Uh-Oh</h1>
              <div className="error-message-container">
                <p className='m-0'>
                  It appears that you failed to turn on location services. In order to coninue and
                  use this app, we will need you to agree to share your location to verify you are
                  in front of the property. Otherwise, you may{' '}
                  <Link to={frontendRoutes.selectTourDate(params.builder_name).path}>
                    reschedule
                  </Link>{' '}
                  or{' '}
                  <Link to={frontendRoutes.chooseCommunity(params.builder_name).path}>
                    start over
                  </Link>
                </p>
              </div>

              <div className='troubleshoot-points'>
                <h4 className='text-danger mt-4 mb-3'>Troubleshooting Steps</h4>
                <ol>
                  <li>Make sure to turn on location sharing in your phone's browser. Here's a helpful resource: <a href="https://nternow.freshdesk.com/en/support/solutions/articles/63000280201-how-to-enable-location-services" rel="noreferrer"  target='_blank'>link</a></li>
                  <li>Make sure you are near or close to the property to TourNow</li>
                  <li>Call live support if in proper location and still receiving error</li>
                </ol>
                <a href="tel:+1-844-531-1283" className="need-help-link d-flex justify-content-center gap-1 mt-3" target="_blank" rel="noreferrer">
                  Need Help? <i className="icn icn-phone"></i>
                </a>
              </div>
            </>
          ) : (
            <>
              <h1 className="h2">Please share your location</h1>
              <p>
                In order to continue and use this app, we will need you to agree to share your
                location to verify you are in front of the property.
              </p>
            </>
          )}
          <div className="box-footer mt-auto">
            <Button onClick={onAccept} className="button w-100">
              Continue
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default ShareLocation;
