import React from 'react';

import copyright from '../../assets/images/icn-logo.svg';

const Footer = () => {
  return (
    <footer>
      <span>
        Powered By
        <a href="https://nternow.com/" rel="noreferrer noopener" target="_blank">
          <img src={copyright} alt="Copyright" />
        </a>
      </span>
    </footer>
  );
};

export default Footer;
