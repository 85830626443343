import Persona from 'persona';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { frontendRoutes } from '../../../Constants/constants';
import { BuilderSettings } from '../../../redux/types/builders';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

const TextVerify = () => {
  const [isLoading, setIsLoading] = useState(true);

  let params = useParams<{ builder_name?: string }>();
  const history = useHistory();
  const builderSettings: BuilderSettings = useSelector((state: any) => state.builder.settings);
  const builder_id: number = useSelector((state: any) => state.builder.builder_id)
  const userInfo = useSelector((state: any) => state.user?.userInfo);
  const isSelfieVerificationActive = builderSettings.selfie_verification_active;
  const isHighSecurityVerificationActive = builderSettings.high_security_verification_active;

  let templateId = isSelfieVerificationActive
    ? process.env.REACT_APP_PERSONA_SELFIE_TEMPLATE_ID
    : process.env.REACT_APP_PERSONA_TEMPLATE_ID;

  if (isHighSecurityVerificationActive) {
    templateId = process.env.REACT_APP_PERSONA_HIGH_SECURITY_TEMPLATE_ID;
  }

  const inquiryFields = {
    "property_id": userInfo?.property_id,
    "email_address": userInfo?.email_1,
    "phone_number": userInfo?.cell_phone_1,
    "builder_id": builder_id || 0,
    "builder": params.builder_name || '',
  }
  
  return (
    <section className="main-section d-flex justify-content-center">
      <div className="box">
        <div className={`box-data d-flex flex-column ${isLoading && 'visually-hidden'}`}>
          <Persona.Inquiry
            templateId={templateId}
            referenceId={userInfo?.cell_phone_1}
            environment="production"
            fields={inquiryFields}
            onReady={() => {
              setIsLoading(false);
              console.log('ready');
            }}
            onLoad={() => {
              console.log('Loaded inline');
            }}
            onComplete={({ inquiryId, status, fields }) => {
              console.log(`Completed inquiry ${inquiryId} with status ${status}`);
              
              if (status === 'failed') {
                history.push(frontendRoutes.phoneVerify(params.builder_name).path);
                return
              }

              localStorage.setItem('fields', JSON.stringify(fields));
              history.push(frontendRoutes.isThisYou(params.builder_name).path);
            }}
          />
        </div>
        {isLoading && <LoadingSpinner />}
      </div>
    </section>
  );
};

export default TextVerify;
// tmpl_XNZZiE9JtSFc4Zys7njU5AgD
// environment='production'
// console.log(`Completed inquiry ${inquiryId} with status ${status}`);
