import {
    GET_SURVEY_QUESTION_REQUEST,
    GET_SURVEY_QUESTION_SUCCESS,
    GET_SURVEY_QUESTION_FAIL
  } from '../types/actionTypes';
  
  export const surveyQuestionReducer = (state: any = {}, action: any) => {
    switch (action.type) {
      case GET_SURVEY_QUESTION_REQUEST:
        return { ...state, loading: true };
      case GET_SURVEY_QUESTION_SUCCESS:
        return { ...state, loading: false, surveyQuestions: action.payload };
      case GET_SURVEY_QUESTION_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  