import { Spinner } from 'react-bootstrap';

const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <Spinner
        className="spinner"
        animation="border"
        role="status"
      />
      <p>Loading...</p>
    </div>
  );
};

export default LoadingSpinner;
