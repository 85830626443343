import 'react-image-gallery/styles/css/image-gallery.css';
import React, { useState } from "react";
import ImageGallery, { ReactImageGalleryProps } from 'react-image-gallery';

const GalleryWithLoader = (props: ReactImageGalleryProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    // When each image loads, remove the loader
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <div className="skeleton"></div>}
      <ImageGallery
        lazyLoad={true}
        showPlayButton={false}
        showFullscreenButton={false}
        {...props}
        additionalClass={isLoading ? 'image-gallery-custom hidden' : 'image-gallery-custom'}
        onImageLoad={() => handleImageLoad()} /* Listen to image load events */
      />
    </>
  );
};

export default GalleryWithLoader;