import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { frontendRoutes } from '../../../Constants/constants';
import { chooseValidation } from '../../../Constants/validations';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addStaticToken,
  choose_community,
  getBuilderId,
  search_community,
} from '../../../redux/action/action';
import Loader from '../../Loader/Loader';
import { Button } from '../../Button/Button';
import { datadogLogs } from '@datadog/browser-logs';
import { BuilderSettings } from '../../../redux/types/builders';
import { honeypot } from '@honeypot-run/core';
import { Disclosure } from '../../Disclosure/Disclosure';


const ChooseCommunity = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ builder_name?: string }>();
  const community = useSelector((state: any) => state.community.community);
  const builderId = useSelector((state: any) => state.builder.builder_id);
  const communityLoading = useSelector((state: any) => state.community.loading);
  const builderSettings: BuilderSettings = useSelector((state: any) => state.builder.settings);

  const formik = useFormik<FormikValues>({
    validateOnChange: false,
    initialValues: {
      choose: null,
      privacyPolicy: false,
      customDisclosureActive: false,
      customDisclosure: false,
    },
    validationSchema: chooseValidation,
    onSubmit: values => {
      const data = { choose: values.choose };
      const communityId = data?.choose?.community_id;

      setIsLoading(true);
      dispatch(search_community(data));
      setTimeout(() => {
        setIsLoading(false);
        honeypot.track(
					'Community Selected',
					{ communityId: communityId ,builderName: params.builder_name });
        history.push(frontendRoutes.community(params.builder_name, communityId).path);
      }, 1000);
    },
  });

  useEffect(() => {
    if (!builderId) {
      dispatch(addStaticToken());
      dispatch(getBuilderId());
    }
  }, [dispatch, builderId]);

  useEffect(() => {
    dispatch(choose_community());
  }, [dispatch]);

  useEffect(() => {
    if (builderId && !communityLoading && (!community || community.length === 0)) {
      datadogLogs.logger.warn('Not found community', { community, builderId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community, communityLoading]);

  const previousPath = () => {
    history.goBack();
  };

  const options =
    community &&
    community.map((a: any) => {
      return { label: a.community.name, value: a.id, community_id: a.community.community_id };
    });

  return (
    <section className="main-section community-screen d-flex justify-content-center">
      <div className="box">
        <Header />
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <div className="box-data d-flex flex-column position-relative">
          <span className="required-text">
            <sup>*</sup>required
          </span>
          <Form onSubmit={formik.handleSubmit} className="d-flex flex-column h-100">
            <FormikProvider value={formik}>
              <Form.Group controlId="community">
                <Form.Label>
                  What community would you like to tour?<sup>*</sup>
                </Form.Label>
                <Select
                  noOptionsMessage={() => 'Currently, there are no homes available for tours'}
                  options={options}
                  onChange={option => formik.setFieldValue('choose', option)}
                  value={formik.values.choose}
                  classNamePrefix="netrnow-select"
                  placeholder="Please select a community"
                />
                {formik.errors.choose ? (
                  <span className="invalid-feedback d-block">{formik.errors.choose}</span>
                ) : (
                  ''
                )}
              </Form.Group>
              <div className="box-footer mt-auto">
                <Disclosure builderSettings={builderSettings} formik={formik} />
                {isLoading && <Loader />}
                <Button className="button w-100" type="submit">
                  Continue
                </Button>
              </div>
            </FormikProvider>
          </Form>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default ChooseCommunity;
