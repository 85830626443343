import React from 'react';
import { Link } from 'react-router-dom';
import icn_license from '../../../assets/images/license-image.png';
import icn_selfie from '../../../assets/images/selfie-image.png';

const documentVerify = () => {
  return (
    <section className="main-section success-screen d-flex justify-content-center">
      <div className="box">
        <div className="status-title success">
          <h1>SUCCESS!</h1>
          <span>We have successfully texted you a verification link.</span>
          <div className="d-flex w-100">
            <Link to="/" className="">
              Resend
            </Link>
          </div>
        </div>
        <div className="box-data d-flex flex-column">
          <p>
            Our identity verification is done in two parts. We capture an image of your ID and then
            capture a selfie. Here are some helpful ways to be prepared.
          </p>
          <ul className="work-item varify-item">
            <li className="d-flex varify-text">
              <figure>
                <img src={icn_license} alt="Verify Identify" />
              </figure>
              <div className="work-text ">
                <ul>
                  <li>
                    Put license on flat surface, make sure thumb or fingers do not cover parts of
                    the license data
                  </li>
                  <li>Make sure text is clear and not blurry</li>
                  <li>Avoid sun and glare</li>
                </ul>
              </div>
            </li>
            <li className="d-flex varify-text">
              <figure>
                <img src={icn_selfie} alt="Code" />
              </figure>
              <div className="work-text ">
                <p>We will require a front-facing image and a side facing image.</p>
              </div>
            </li>
          </ul>
          {/* <div className="box-footer mt-auto">
						<Link to="/is-this-you" className="button w-100">Text verification link</Link>
		        	</div> */}
        </div>
      </div>
    </section>
  );
};

export default documentVerify;
