import { frontendRoutes } from '../../../Constants/constants';
import { Link, useParams } from 'react-router-dom';

interface CancelTourSuccessInterface {
  message:MessageType | null
}

type MessageType = {
  isSuccess:boolean,
  message:string
}

export function CancelTourSuccess({message}: CancelTourSuccessInterface) {
    let params = useParams<{ builder_name?: string }>();

    return (
        <section className=" cancel-tour-section main-section success-screen tour-success-screen d-flex justify-content-center">
        <div className={`cancel-tour-success-main status-title ${message?.isSuccess ? 'success' : 'error'}`}>
          <h1 className='cancel-tour-heading'>{message?.isSuccess ? 'SUCCESS!' : 'ERROR!'}</h1>
          <span>
            { message?.message || "Your tour has been cancelled" }
          </span>
          <div className="d-flex w-100">
            <Link
              to={frontendRoutes.chooseCommunity(params.builder_name).path}
              className=""
            >
              Reschedule
            </Link>
          </div>
        </div>
    </section>
    )
}
