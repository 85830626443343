import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getBuilderId } from '../../../redux/action/action';
import { FormikProvider, useFormik } from 'formik';
import { Button } from '../../Button/Button';
import { Form, Spinner } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import { Rating } from 'react-simple-star-rating'
import Swal from 'sweetalert2';
import { backendRoutes, frontendRoutes } from '../../../Constants/constants';
import axios from 'axios';
import { BuilderSettings } from '../../../redux/types/builders';


const tooltipArray = [
  "Terrible",
  "Terrible+",
  "Bad",
  "Bad+",
  "Average",
  "Average+",
  "Great",
  "Great+",
  "Awesome",
  "Awesome+"
];
const fillColorArray = [
  "#f17a45",
  "#f17a45",
  "#f19745",
  "#f19745",
  "#f1a545",
  "#f1a545",
  "#f1b345",
  "#f1b345",
  "#f1d045",
  "#f1d045"
];


const Feedback = () => {

  const history = useHistory();
	const dispatch = useDispatch();
  const params = useParams<{ builder_name?: string }>();

  const [lovedFocused, setLovedFocused] = useState(false)
  const [dislikedFocused, setDislikedFocused] = useState(false)
  const [rating, setRating] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState('')

	const builderId = useSelector((state: any) => state.builder.builder_id);
  const builderSettings: BuilderSettings = useSelector((state: any) => state.builder.settings);
  
  const user_token = localStorage.getItem('user_token');
  const visit_id = localStorage.getItem('visit_id');
  const propertyId = localStorage.getItem('pid');

  const formik = useFormik({
    initialValues: {
      loved: '',
      disliked: '',
      rating: 0
    },
    enableReinitialize: true,
    onSubmit: async values => {

      const remarks = `
      User sent feedback:

      ${values.rating} of 5 stars;

      Comments:
      Loved: ${values.loved}

      Disliked: ${values.disliked}`

      const formData = {
        propertyId,
        remarks
      }
      setIsLoading(true);
      axios({
        baseURL: backendRoutes.MOBILE_BASE_URL,
        url: `visits/${visit_id}`,
        method: 'PATCH',
        headers: {
          'x-auth-token': user_token,
        },
        data: formData,
      })
        .then(res => {
          setIsLoading(false);
          if (res.status === 200) {
            setIsError('')
            successModal();
          }
        })
        .catch(err => {
          setIsLoading(false);
          const msg = err.response.data;
          setIsError(msg)
          console.log(msg);
        });
    },
  });

  useEffect(() => {
    !builderId && dispatch(getBuilderId());
}, [builderId, dispatch])

	const previousPath = () => {
		history.goBack();
	}

  const handleRating = (rate: number) => {
    setRating(rate)
    formik.setFieldValue('rating', rate);
  }

  const successModal = () => {
    Swal.fire({
      title: "Thank You!",
      text: "Your feedback has been successfully submitted.",
      icon: "success",
      titleText: "",
      showDenyButton: true,
      denyButtonColor: builderSettings.primary_color,
      denyButtonText: "Show More Properties",
      confirmButtonColor: builderSettings.primary_color,
      confirmButtonText: "Ready To Buy", // Customize the text on the "OK" button
      reverseButtons: true,
      customClass: {
        actions: 'vertical-buttons',
      }
    }).then((result) => {
      // Check if the user clicked the "OK" button
      if (result.isConfirmed) {
        // Redirect to your desired route
        history.push(frontendRoutes.offer(params.builder_name).path);
      } else {
        history.push(frontendRoutes.chooseCommunity(params.builder_name).path);
      }
    });

  }

  return (
    <section className="main-section feedback-screen d-flex justify-content-center">
			<div className="box">
				<div className="main-title">
					<h1>Feedback</h1>
				</div>
				<span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
				<div className="box-data d-flex flex-column">
          <p>Please rate your house tour experience!<span className='text-danger'>*</span></p>
          <Form
            className="material-form d-flex flex-column h-100"
            onSubmit={formik.handleSubmit}
            >
                <FormikProvider value={formik}>
                    <Rating
                      onClick={handleRating}
                      initialValue={formik.values.rating}
                      transition
                      emptyColor="#e7e7e7"
                      size={35}
                      showTooltip={true}
                      allowFraction={true}
                      tooltipStyle={{ color: fillColorArray[Math.ceil(rating)] }}
                      tooltipArray={tooltipArray}
                      fillColorArray={fillColorArray}
                    />

                    <Form.Group
                        className={`${ lovedFocused === false && formik.values.loved === ''
                        ? 'form-group'
                        : 'form-group input-focus'} mt-4`}
                        controlId="loved"
                    >
                        <Form.Control
                          as="textarea" 
                          rows={3}
                          name="loved"
                          placeholder='Loved about this property...'
                          onChange={formik.handleChange}
                          value={formik.values.loved}
                          onFocus={() => setLovedFocused(true)}
                          onBlur={e => {
                            setLovedFocused(false);
                            formik.handleBlur(e);
                          }}
                        />
                    </Form.Group>

                    <Form.Group
                        className={`${ dislikedFocused === false && formik.values.disliked === ''
                        ? 'form-group'
                        : 'form-group input-focus'}`}
                        controlId="disliked"
                    >
                        <Form.Control
                          as="textarea" 
                          rows={3}
                          name="disliked"
                          placeholder='Disliked about this property...'
                          onChange={formik.handleChange}
                          value={formik.values.disliked}
                          onFocus={() => setDislikedFocused(true)}
                          onBlur={e => {
                            setDislikedFocused(false);
                            formik.handleBlur(e);
                          }}
                        />
                    </Form.Group>

                    <div className="box-footer mt-auto">
                      
                        {isLoading ? 
                          <Button className="button w-100 d-flex justify-content-center align-items-center spinner-btn" type="button">
                            <Spinner animation="border" />
                          </Button>
                          :
                          <Button 
                          disabled={formik.values.rating === 0}
                          style={{ background: formik.values.rating === 0 ? 'silver' : builderSettings?.primary_color}}
                          className="button w-100 d-flex justify-content-center align-items-center spinner-btn" 
                          type="submit">
                            Submit
                          </Button>
                          
                          }
                    </div>
                </FormikProvider>
            </Form>
            {isError && <p className='mt-3 text-danger'>{isError}</p>}
				</div>
        <Footer />
			</div>
		</section>
  )
}

export default Feedback