import { PERSONA_DATA_FAIL, PERSONA_DATA_REQUEST, PERSONA_DATA_SUCCESS } from "../types/actionTypes";

const personaDataReducer = (state={},action:any) => {
    switch (action.type) {
        case PERSONA_DATA_REQUEST:
          return { ...state, loading: true };
        case PERSONA_DATA_SUCCESS:
          return { loading: false, fields: action.payload };
        case PERSONA_DATA_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
}

export default personaDataReducer
