import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

import { frontendRoutes } from '../../../Constants/constants';
import { userValidationSchema } from '../../../Constants/validations';
import { registerUser } from '../../../redux/action/action';
// import InputMask from 'react-input-mask';
import { changePhoneNumberFormat } from '../../../utils';

const IsThisYou = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let params = useParams<{ builder_name?: string }>();
  const [firstNameFocus, setFirstNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [addressFocus, setAddressFocus] = useState(false);
  const [zipcodeFocus, setZipcodeFocus] = useState(false);
  // const [phNumberFocus, setPhNumberFocus] = useState(false);
  const isFields = () => {
    const fields = localStorage.getItem('fields');
    if (fields) {
      return true;
    }

    return false;
  };

  const fieldItem = isFields() ? JSON.parse(localStorage.getItem('fields') || '{}') : '';
  var firstname = fieldItem['name-first']?.value;
  var lastname = fieldItem['name-last']?.value;
  var postalcode = fieldItem['address-postal-code']?.value;
  var street = fieldItem['address-street-1']?.value;
  var phNumber = fieldItem['phone-number']?.value;

  const phoneNumber = useSelector((state: any) => state.phNumber.phNumber?.phone);
  const goToPreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!phoneNumber) {
      history.push(frontendRoutes.phoneVerify(params.builder_name).path);
    }
  
  }, [history, params.builder_name, phoneNumber])
  

  const formik = useFormik({
    initialValues: isFields()
      ? {
          firstName: firstname || '',
          lastName: lastname || '',
          streetAddress: street || '',
          zipCode: postalcode || '',
          phoneNumber: changePhoneNumberFormat(phNumber) || phoneNumber,
          email: '',
          isAgent: 0,
        }
      : {
          firstName: '',
          lastName: '',
          streetAddress: '',
          zipCode: '',
          phoneNumber: '',
          email: '',
          isAgent: 0,
        },
    validationSchema: userValidationSchema,
    validateOnChange: false,
    validateOnBlur:false,
    onSubmit: values => {
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        streetAddress: values.streetAddress,
        zipCode: values.zipCode,
        phoneNumber: values.phoneNumber,
        email: values.email,
        isAgent: values.isAgent,
      };

      if (values.phoneNumber !== phoneNumber) {
        datadogLogs.logger.warn(
          `Phone numbers do not match! 2fa number: ${phoneNumber}, customer number: ${values.phoneNumber}`,
        );
      }

      dispatch(registerUser(data));
      history.push(frontendRoutes.emailVerify(params.builder_name).path);
    },
  });
  return (
    <section className="main-section d-flex justify-content-center">
      <div className="box">
        <div className="main-title">
          <h1>Is this you?</h1>
          <span>
            <sup>*</sup>required
          </span>
        </div>
        {/* focus?"form-group input-focus":"form-group" */}
        <span className="link-back" onClick={goToPreviousPage}>
          Cancel
        </span>
        <div className="box-data d-flex flex-column">
          <Form
            className="material-form h-100 d-flex flex-column"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <FormikProvider value={formik}>
              <Form.Group
                className={
                  firstNameFocus === false && formik.values.firstName === ''
                    ? 'form-group'
                    : 'form-group input-focus'
                }
                controlId="first-name"
              >
                <Form.Control
                  type="text"
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  isInvalid={formik.touched.firstName && !!formik.errors.firstName}
                  onFocus={() => setFirstNameFocus(true)}
                  onBlur={e => {
                    setFirstNameFocus(false);
                    formik.handleBlur(e);
                  }}
                />
                <Form.Label>
                  First name<sup>*</sup>
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className={
                  lastNameFocus === false && formik.values.lastName === ''
                    ? 'form-group'
                    : 'form-group input-focus'
                }
                controlId="last-name"
              >
                <Form.Control
                  type="text"
                  name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  isInvalid={formik.touched.lastName && !!formik.errors.lastName}
                  onFocus={() => setLastNameFocus(true)}
                  onBlur={e => {
                    setLastNameFocus(false);
                    formik.handleBlur(e);
                  }}
                />
                <Form.Label>
                  Last name<sup>*</sup>
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className={
                  addressFocus === false && formik.values.streetAddress === ''
                    ? 'form-group'
                    : 'form-group input-focus'
                }
                controlId="address"
              >
                <Form.Control
                  type="text"
                  name="streetAddress"
                  onChange={formik.handleChange}
                  value={formik.values.streetAddress}
                  isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress}
                  onFocus={() => setAddressFocus(true)}
                  onBlur={e => {
                    setAddressFocus(false);
                    formik.handleBlur(e);
                  }}
                />
                <Form.Label>
                  Street address<sup>*</sup>
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.streetAddress}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className={
                  zipcodeFocus === false && formik.values.zipCode === ''
                    ? 'form-group'
                    : 'form-group input-focus'
                }
                controlId="zip-code"
              >
                <Form.Control
                  type="text"
                  name="zipCode"
                  onChange={formik.handleChange}
                  value={formik.values.zipCode}
                  isInvalid={formik.touched.zipCode && !!formik.errors.zipCode}
                  onFocus={() => setZipcodeFocus(true)}
                  onBlur={e => {
                    setZipcodeFocus(true);
                    formik.handleBlur(e);
                  }}
                />
                <Form.Label>
                  Zipcode<sup>*</sup>
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.zipCode}
                </Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group
                className={
                  phNumberFocus === false && formik.values.phoneNumber === ''
                    ? 'form-group'
                    : 'form-group input-focus'
                }
                controlId="phone-number"
              >
                <InputMask
                  mask="999-999-9999"
                  onChange={formik.handleChange}
                  onFocus={() => setPhNumberFocus(true)}
                  onBlur={e => {
                    setPhNumberFocus(false);
                    formik.handleBlur(e);
                  }}
                  value={formik.values.phoneNumber}
                >
                  {() => (
                    <Form.Control
                      type="text"
                      name="phoneNumber"
                      isInvalid={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
                    />
                  )}
                </InputMask>
                <Form.Label>
                  Phone Number<sup>*</sup>
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.phoneNumber}
                </Form.Control.Feedback>
              </Form.Group> */}
              <Form.Group
                className={
                  emailFocus === false && formik.values.email === ''
                    ? 'form-group'
                    : 'form-group input-focus'
                }
                controlId="email"
              >
                <Form.Control
                  type="text"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  isInvalid={formik.touched.email && !!formik.errors.email}
                  onFocus={() => setEmailFocus(true)}
                  onBlur={e => {
                    setEmailFocus(false);
                    formik.handleBlur(e);
                  }}
                />
                <Form.Label>
                  Email<sup>*</sup>
                </Form.Label>
                <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className={
                  formik.errors.isAgent
                    ? 'form-group form-group-radio is-invalid'
                    : 'form-group form-group-radio'
                }
              >
                <Form.Label>
                  Please select one.<sup>*</sup>
                </Form.Label>
                <Form.Check
                  type="radio"
                  label="I am an agent"
                  name="isAgent"
                  id="radio-agent"
                  value={1}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.isAgent && !!formik.errors.isAgent}
                  onBlur={e => formik.handleBlur(e)}
                />
                <Form.Check
                  type="radio"
                  label="I am NOT an agent"
                  name="isAgent"
                  id="radio-agent-not"
                  value={0}
                  onChange={formik.handleChange}
                  defaultChecked
                  isInvalid={!!formik.errors.isAgent}
                  onBlur={e => formik.handleBlur(e)}
                />
                <span className="invalid-feedback">{formik.errors.isAgent ? 'Required' : ''}</span>
              </Form.Group>
              <div className="box-footer mt-auto">
                <button type="submit" className="button w-100">
                  Yes It's Me
                </button>
                <span className="policy-text">
                  <i className="icn icn-lock"></i>Your information is protected by our{' '}
                  <a href="https://nternow.com/" rel="noreferrer" target="_blank">
                    privay policy
                  </a>
                  .
                </span>
              </div>
            </FormikProvider>
          </Form>
        </div>
      </div>
    </section>
  );
};

export default IsThisYou;
