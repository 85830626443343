import axios from 'axios';
import { backendRoutes } from '../../Constants/constants';
import { honeypot } from '@honeypot-run/core';

import {
  BUILDER_SETTINGS_FAIL,
  BUILDER_SETTINGS_REQUEST,
  BUILDER_SETTINGS_SUCCESS,
  CHOOSE_COMMUNITY_FAIL,
  CHOOSE_COMMUNITY_REQUEST,
  CHOOSE_COMMUNITY_SUCCESS,
  GET_PROPERTY_FAIL,
  GET_PROPERTY_REQUEST,
  GET_PROPERTY_SUCCESS,
  PHONE_NUMBER_FAIL,
  PHONE_NUMBER_REQUEST,
  PHONE_NUMBER_SUCCESS,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  SEARCH_COMMUNITY_FAIL,
  SEARCH_COMMUNITY_REQUEST,
  SEARCH_COMMUNITY_SUCCESS,
  TOUR_DATE_FAIL,
  TOUR_DATE_REQUEST,
  TOUR_DATE_SUCCESS,
  USER_DETAIL_FAIL,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  SCHEDULED_VISITS_REQUEST,
  SCHEDULED_VISITS_CANCEL,
  SCHEDULED_VISITS_SUCCESS,
  SCHEDULED_VISITS_FAIL,
  GET_PROPERTY_ADDITIONAL_INFO_REQUEST,
  GET_PROPERTY_ADDITIONAL_INFO_SUCCESS,
  GET_PROPERTY_ADDITIONAL_INFO_FAIL,
  AVAILABLE_TOUR_HOURS_REQUEST,
  AVAILABLE_TOUR_HOURS_SUCCESS,
  AVAILABLE_TOUR_HOURS_FAIL,
  ADD_PROPERTY_ID,
  GET_BUILDER_ID_REQUEST,
  GET_BUILDER_ID_SUCCESS,
  GET_BUILDER_ID_FAIL,
  ADD_STATIC_TOKEN_REQUEST,
  ADD_STATIC_TOKEN_SUCCESS,
  ADD_STATIC_TOKEN_FAIL,
  GET_SURVEY_QUESTION_REQUEST,
  GET_SURVEY_QUESTION_SUCCESS,
  GET_SURVEY_QUESTION_FAIL,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAIL,
  GET_SCHEDULED_TOURS_REQUEST,
  GET_SCHEDULED_TOURS_SUCCESS,
  GET_SCHEDULED_TOURS_FAIL,
  GET_COMPLETED_TOURS_REQUEST,
  GET_COMPLETED_TOURS_SUCCESS,
  GET_COMPLETED_TOURS_FAIL,
  ADD_SCHEDULED_VISIT_ID,
  GET_BUILDER_RENTAL_SUCCESS,
  ADD_UTM_SOURCE,
} from '../types/actionTypes';
import { datadogLogs } from '@datadog/browser-logs';

export const registerUser = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: REGISTER_USER_REQUEST,
    });
    dispatch({
      type: REGISTER_USER_SUCCESS,
      payload: formData,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAIL_FAIL,
      payload: error,
    });
  }
};
export const userDetail = (formData: any, history: any, path: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_DETAIL_REQUEST,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('static_token'),
      },
    };
    const { data } = await axios.post(
      backendRoutes.MOBILE_BASE_URL + backendRoutes.customers,
      formData,
      config,
    );

    honeypot.track(
      'Sign Up - Success',
      { email: formData.email, phone: formData.phoneNumber, firstName: formData.firstName, lastName: formData.lastName });

    localStorage.setItem('user_token', data.token);
    dispatch({
      type: USER_DETAIL_SUCCESS,
      payload: localStorage.setItem('user_token', data.token),
    });

    history.push(path);
  } catch (error) {
    dispatch({
      type: USER_DETAIL_FAIL,
      payload: error,
    });
  }
};

export const getUserInfo = (id: number) => async (dispatch: any) => {

  try {
    dispatch({
      type: GET_USER_INFO_REQUEST,
    });

    const token = localStorage.getItem('user_token')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    };
    const { data } = await axios.get(`${backendRoutes.MOBILE_BASE_URL}customers/${id}`, config)

    dispatch({
      type: GET_USER_INFO_SUCCESS,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: GET_USER_INFO_FAIL,
      payload: error,
    });
  }
}

export const personaData = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: PHONE_NUMBER_REQUEST,
    });

    dispatch({
      type: PHONE_NUMBER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PHONE_NUMBER_FAIL,
      payload: error,
    });
  }
};

export const choose_community = () => async (dispatch: any, getState: any) => {
  const state = getState();
  
  // const token = state.user.token;
  const userToken = localStorage.getItem('user_token');
  const staticToken = localStorage.getItem('static_token');
  const id = state.builder.builder_id;

  try {
    dispatch({
      type: CHOOSE_COMMUNITY_REQUEST,
    });
    const config = {
      params: { isAvailable: true },
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken ? userToken : staticToken,
      },
    };
    const { data } = await axios.get(
      `${backendRoutes.MOBILE_BASE_URL}properties/communities/${id}`,
      config,
    );
    const arr = data.map((community: any, id: any) => {
      return { community, id };
    });
    dispatch({
      type: CHOOSE_COMMUNITY_SUCCESS,
      payload: arr,
    });
  } catch (error) {
    dispatch({
      type: CHOOSE_COMMUNITY_FAIL,
      payload: error,
    });
    datadogLogs.logger.error(
      'Error in choose_community action',
      {
        builder_id: id,
      },
      error,
    );
  }
};

export const search_community = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SEARCH_COMMUNITY_REQUEST,
    });

    dispatch({
      type: SEARCH_COMMUNITY_SUCCESS,
      payload: formData,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_COMMUNITY_FAIL,
      payload: error,
    });
  }
};

export const get_property_details = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_PROPERTY_REQUEST,
    });
    dispatch({
      type: GET_PROPERTY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PROPERTY_FAIL,
      payload: error,
    });
  }
};

export const addPropertyId = (data: string | number) => ({
  type: ADD_PROPERTY_ID,
  payload: data,
});

export const addScheduledVisitId = (data: string | number) => {
  return ({
  type: ADD_SCHEDULED_VISIT_ID,
  payload: data,
})};

export const addUTMSource = (data: string) => ({
  type: ADD_UTM_SOURCE,
  payload: data
})

export const addStaticToken = () => async (dispatch: any) => {
  try {
    dispatch({ type: ADD_STATIC_TOKEN_REQUEST });

    const { data } = await axios({
      baseURL: backendRoutes.MOBILE_BASE_URL,
      url: backendRoutes.static_token,
      method: 'get',
    });

    localStorage.setItem('static_token', data.token);
    
    dispatch({
      type: ADD_STATIC_TOKEN_SUCCESS,
      payload: data.token,
    });
  } catch (error: any) {
    dispatch({
      type: ADD_STATIC_TOKEN_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const getSurveyQuestions = (id: any) => async (dispatch: any) => {
  try {
    dispatch({ type: GET_SURVEY_QUESTION_REQUEST });

    const { data } = await axios(`${backendRoutes.MOBILE_BASE_URL}survey/questions/builder/${id}`);
    
    dispatch({
      type: GET_SURVEY_QUESTION_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_SURVEY_QUESTION_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const tourDate = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: TOUR_DATE_REQUEST,
    });

    dispatch({
      type: TOUR_DATE_SUCCESS,
      payload: formData,
    });
  } catch (error) {
    dispatch({
      type: TOUR_DATE_FAIL,
      payload: error,
    });
  }
};

export const getPhoneNumber = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: PHONE_NUMBER_REQUEST,
    });

    dispatch({
      type: PHONE_NUMBER_SUCCESS,
      payload: formData,
    });
  } catch (error) {
    dispatch({
      type: PHONE_NUMBER_FAIL,
      payload: error,
    });
  }
};

export const scheduledVisits = (visit: any, utmSource: string) => async (dispatch: any) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': localStorage.getItem('user_token'),
    },
  };

  try {
    dispatch({ type: SCHEDULED_VISITS_REQUEST });

    await axios.post(`${backendRoutes.MOBILE_BASE_URL}scheduled-visits?utm_source=${utmSource}`, visit, config);

    dispatch({
      type: SCHEDULED_VISITS_SUCCESS,
      payload: visit,
    });
  } catch (error: any) {
    console.log('scheduledVisits > error', error);
    dispatch({
      type: SCHEDULED_VISITS_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const cancelVisits = (visit: any,setIsLoading:any) => async (dispatch: any) => {
  setIsLoading(true)
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': localStorage.getItem('user_token'),
    },
  };

  try {
    dispatch({ type: SCHEDULED_VISITS_CANCEL });

    const response = await axios.post(`${backendRoutes.MOBILE_BASE_URL}scheduled-visits/cancelScheduledProperty`, visit, config);

    dispatch({
      type: SCHEDULED_VISITS_SUCCESS,
      payload: visit,
    });
    setIsLoading(false)
    return response
  } catch (error: any) {
    setIsLoading(false)
    dispatch({
      type: SCHEDULED_VISITS_FAIL,
      payload: error?.response?.data,
    });
  }
};
export const getBuilderId = () => async (dispatch: any, getState: any) => {
  const state = getState();
  try {
    if (!state.builder.builder_id) {
      dispatch({
        type: GET_BUILDER_ID_REQUEST,
      });

      const builderName = window.location.pathname.split('/')[1];

      const { data } = await axios({
        baseURL: backendRoutes.MOBILE_BASE_URL,
        url: `builders/name/${builderName}`,
        method: 'GET',
      });
      
      dispatch({
        type: GET_BUILDER_ID_SUCCESS,
        payload: data.builderId,
      });

      dispatch({
        type: GET_BUILDER_RENTAL_SUCCESS,
        payload: data.isRental,
      });
      
      localStorage.setItem('builder_id', data.builderId);
    }

    if (!state.builder.settings.builder_id) {
      dispatch(getBuilderSettings());
    }

    // if (!state.community.community) {
      dispatch(choose_community());
    // }

  } catch (error) {
    datadogLogs.logger.error(
      `INVALID BUILDER NAME IN URL: ${window.location.pathname} --> CustomerId: ${state.user.userInfo?.customer_id}`,
    )
    dispatch({
      type: GET_BUILDER_ID_FAIL,
      payload: error,
    });
    dispatch(getBuilderSettings());
  }
};

export const getBuilderSettings = () => async (dispatch: any, getState: any) => {
  const { builder } = getState();

  try {
    dispatch({
      type: BUILDER_SETTINGS_REQUEST,
    });

    const { data } = await axios.get(
      `${backendRoutes.MOBILE_BASE_URL}builders/settings/${builder?.builder_id}`,
    );

    dispatch({
      type: BUILDER_SETTINGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BUILDER_SETTINGS_FAIL,
      payload: error,
    });
  }
};

export const getAvailableTourHours =
  (propertyId: number, date: { year: number; month: number; day: number }) =>
  async (dispatch: any) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
    };

    try {
      dispatch({
        type: AVAILABLE_TOUR_HOURS_REQUEST,
      });

      const { data } = await axios.post(
        `${backendRoutes.MOBILE_BASE_URL}properties/${propertyId}/availability`,
        date,
        config,
      );

      dispatch({
        type: AVAILABLE_TOUR_HOURS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: AVAILABLE_TOUR_HOURS_FAIL,
        payload: error,
      });
    }
  };

export const getPropertyAdditionalInfo = (propertyId: number) => async (dispatch: any) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('static_token'),
      },
    };

    dispatch({
      type: GET_PROPERTY_ADDITIONAL_INFO_REQUEST,
    });

    const { data } = await axios.get(
      `${backendRoutes.MOBILE_BASE_URL}properties/${propertyId}/additional-info`,
      config,
    );

    dispatch({
      type: GET_PROPERTY_ADDITIONAL_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PROPERTY_ADDITIONAL_INFO_FAIL,
      payload: error,
    });
  }
};

export const getPropertyInfo = (propertyId: number) => async (dispatch: any) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('static_token'),
      },
    };

    dispatch({
      type: GET_PROPERTY_REQUEST,
    });

    const { data } = await axios.get(
      `${backendRoutes.MOBILE_BASE_URL}properties/${propertyId}/info`,
      config,
    );

    dispatch({
      type: GET_PROPERTY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PROPERTY_FAIL,
      payload: error,
    });
  }
};

export const getScheduledTours = (customerId: number, page: number) => async (dispatch: any) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
    };

    dispatch({
      type: GET_SCHEDULED_TOURS_REQUEST,
    });

    const {data} = await axios.get(`${backendRoutes.MOBILE_BASE_URL}scheduled-visits/${customerId}?page=${page}&limit=10`, config)

    dispatch({
      type: GET_SCHEDULED_TOURS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SCHEDULED_TOURS_FAIL,
      payload: error,
    });
  }
};

export const getCompletedTours = (customerId: number, page: number) => async (dispatch: any) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
    };
  
    dispatch({
      type: GET_COMPLETED_TOURS_REQUEST,
    });

    const {data} = await axios.get(`${backendRoutes.MOBILE_BASE_URL}visits/${customerId}?page=${page}&limit=10`, config)

    dispatch({
      type: GET_COMPLETED_TOURS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_COMPLETED_TOURS_FAIL,
      payload: error,
    });
  }
};
