import {
  ADD_PROPERTY_ID,
  AVAILABLE_TOUR_HOURS_FAIL,
  AVAILABLE_TOUR_HOURS_REQUEST,
  AVAILABLE_TOUR_HOURS_SUCCESS,
  GET_PROPERTY_ADDITIONAL_INFO_FAIL,
  GET_PROPERTY_ADDITIONAL_INFO_REQUEST,
  GET_PROPERTY_ADDITIONAL_INFO_SUCCESS,
  GET_PROPERTY_FAIL,
  GET_PROPERTY_REQUEST,
  GET_PROPERTY_SUCCESS,
} from '../types/actionTypes';

export const getPropertyReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case ADD_PROPERTY_ID:
      return { ...state, property: { ...state.property, id: action.payload } };
    case GET_PROPERTY_REQUEST:
      return { ...state, loading: true };
    case GET_PROPERTY_SUCCESS:
      return { ...state, loading: false, property: action.payload };
    case GET_PROPERTY_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_PROPERTY_ADDITIONAL_INFO_REQUEST:
      return { ...state, loading: true };
    case GET_PROPERTY_ADDITIONAL_INFO_SUCCESS:
      return { ...state, loading: false, additionalInfo: action.payload };
    case GET_PROPERTY_ADDITIONAL_INFO_FAIL:
      return { ...state, loading: false, error: action.payload };
    case AVAILABLE_TOUR_HOURS_REQUEST:
      return { ...state, loading: true };
    case AVAILABLE_TOUR_HOURS_SUCCESS:
      return { ...state, loading: false, availableTourHours: action.payload };
    case AVAILABLE_TOUR_HOURS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
