import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import './App.css';
import ChooseCommunity from './Components/LandingPage/Community/ChooseCommunity';
import SearchCommunity from './Components/LandingPage/Community/SearchCommunity';
import documentVerify from './Components/LandingPage/Document-Verification/documentVerify';
import EmailVerify from './Components/LandingPage/EmailVerification/EmailVerify';
import HowitWork from './Components/LandingPage/How-it-works/HowitWork';
import IsThisYou from './Components/LandingPage/Is-this-you/IsThisYou';
import OtpVerification from './Components/LandingPage/OtpVerification/OtpVerification';
import PhoneVerification from './Components/LandingPage/PhoneVerification/PhoneVerification';
import ShareLocation from './Components/LandingPage/ShareLocation/ShareLocation';
import SignInForm from './Components/LandingPage/SigninForm/SignupForm';
import ConfirmScreen from './Components/LandingPage/SuccessPage/TourSuccess/ConfirmScreen';
import LockScreen from './Components/LandingPage/SuccessPage/TourSuccess/LockScreen';
import TextVerify from './Components/LandingPage/Text-Verification/TextVerify';
import SelectTourDate from './Components/LandingPage/TourPreference/SelectTourDate';
import TourHome from './Components/LandingPage/TourPreference/TourHome';
import TourPrefereces from './Components/LandingPage/TourPreference/TourPrefereces';
import TourCancellation from './Components/LandingPage/TourCanelation/TourCancel'
import { frontendRoutes } from './Constants/constants';
import { addPropertyId, addScheduledVisitId, addUTMSource, getUserInfo } from './redux/action/action';
import { BuilderSettings } from './redux/types/builders';
import './style.scss';
import { isUserToken, setBuilderColor } from './utils';
import MobileMenu from './Components/MobileMenu/MobileMenu';
import SurveyQuestions from './Components/LandingPage/SurveyQuestions/SurveyQuestions';
import PreviousTours from './Components/LandingPage/Tours/PreviousTours';
import UpcomingTours from './Components/LandingPage/Tours/UpcomingTours';
import EditProfile from './Components/LandingPage/account/EditProfile';
import HomePage from './Components/LandingPage/HomePage/HomePage';
import jwt_decode from 'jwt-decode';
import LoadingSpinner from './Components/LoadingSpinner/LoadingSpinner';
import { ChooseTour } from './Components/LandingPage/ChooseTour/ChooseTour';
import { EnterProperty } from './Components/LandingPage/EnterProperty/EnterProperty';
import Feedback from './Components/LandingPage/Feedback/Feedback';
// import axios from 'axios';
import  Offer  from './Components/LandingPage/Offer/Offer';
// import PropertyMap from './Components/LandingPage/PropertyMap/PropertyMap';

interface tokenPayload {
  customerId: number;
  username: string;
  iat: number;
  exp: number;
}

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props =>
        isUserToken() ? (
          <Component {...props} />
        ) : (
          <Redirect to={frontendRoutes.signIn(window.location.pathname.split('/')[1]).path} />
        )
      }
    />
  );
};

const App = (props: any) => {
  const location = useLocation()
  const dispatch = useDispatch();
  const propertyId = window.location.pathname.split('/property/')[1];
  const scheduledVisitId = window.location.search.split('?scheduledVisitId=')[1]

  const queryParams = new URLSearchParams(location.search);
  const utmSource = queryParams.get('utm_source')
  
  const builderSettings: BuilderSettings = useSelector((state: any) => state.builder.settings);

  const builderIdLoading = useSelector((state: any) => state.builder.loading);
  const builderSettingsLoading = useSelector((state: any) => state.builder.loadingBuilderSettings);
  // const user = useSelector((state: any) => state.user.userInfo);

  const [isLoading, setIsLoading] = useState(true);
  // const [userLocation, setUserLocation] = useState(null);

  if (propertyId) {
    dispatch(addPropertyId(Number(propertyId)));
  }

  if (scheduledVisitId) {
    dispatch(addScheduledVisitId(Number(scheduledVisitId)));
  }

  if (utmSource) {
    dispatch(addUTMSource(utmSource));
  }

  useEffect(() => {
    localStorage.setItem('builder_name', location.pathname.split('/')[1]);
    const userToken = localStorage.getItem('user_token');
    if (userToken) {
      const customer: tokenPayload = jwt_decode(userToken);

      isUserToken()
        ? dispatch(getUserInfo(customer?.customerId))
        : localStorage.removeItem('user_token');
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    
    (builderSettingsLoading || builderIdLoading) ? setIsLoading(true) : setIsLoading(false)
  }, [builderSettingsLoading, builderIdLoading])

  useEffect(() => {
    if (builderSettings.primary_color) {
      setBuilderColor(builderSettings.primary_color);
    }
  }, [builderSettings.primary_color]);

  // useEffect(() => {
  //   const getUserLocation = async () => {
  //     try {
  //       const { data } = await axios.get('https://geolocation-db.com/json/');

  //       setUserLocation(data);

  //       return data;
  //     } catch (error) {
  //       console.log('error:', error);
  //     }
  //   };

  //   if (!userLocation) {
  //     getUserLocation();
  //   }

  //   // @ts-ignore
  //   if (user && window.heap.identify) {
  //     // @ts-ignore
  //     window.heap.identify(user.customer_id);
  //     // @ts-ignore
  //     window.heap.addUserProperties({
  //       'First Name': user.firstname,
  //       'Last Name': user.lastname,
  //       'Customer ID': user.customer_id,
  //       Phone: user.cell_phone_1,
  //       Email: user.email_1,
  //       Location: userLocation
  //     });
  //   }
  // }, [user, userLocation]);

  return (
    <>
      {isLoading && 
        <div className='loading-spinner'>
          <LoadingSpinner />
        </div> 
      }

      <MobileMenu builderImage={builderSettings.logo} />

      <svg
        className="wave"
        width="1366"
        height="282"
        viewBox="0 0 1366 282"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M988 194C762 134.526 646 134.545 384 206C122 277.455 -8 282 -8 282V3.05176e-05H1372V282C1309.21 282 1166 240.842 988 194Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="567"
            y1="197.395"
            x2="562.912"
            y2="-9.19046"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={builderSettings.primary_color || '#EAF3FA'} />
            <stop offset="1" stopColor={builderSettings.secondary_color || '#FFF'} />
          </linearGradient>
        </defs>
      </svg>

      <Switch>
        {/* <Route exact path="/:builder_name" component={CommunityScreen} /> */}
        {/* <Route exact path="/" component={HomePage} /> */}
        <Route exact path="/:builder_name" component={HomePage} />
        {/* <Route exact path={frontendRoutes.propertyMap().path} component={PropertyMap} /> */}
        <Route exact path={frontendRoutes.chooseTour().path} component={ChooseTour} />
        <Route exact path={frontendRoutes.enterProperty().path} component={EnterProperty} />
        <Route exact path={frontendRoutes.community().path} component={SearchCommunity} />
        <Route exact path={frontendRoutes.signIn().path} component={SignInForm} />
        <Route exact path={frontendRoutes.howItWorks().path} component={HowitWork} />

        {/* Messaging Token */}
        <Route exact path={frontendRoutes.phoneVerify().path} component={PhoneVerification} />
        <Route exact path={frontendRoutes.otpVerify().path} component={OtpVerification} />
        <Route exact path={frontendRoutes.documentVerify().path} component={documentVerify} />
        <Route exact path={frontendRoutes.textVerify().path} component={TextVerify} />

        {/* <Route exact path={frontendRoutes.success().path} component={SuccessPage} /> */}
        <Route exact path={frontendRoutes.isThisYou().path} component={IsThisYou} />
        <Route exact path={frontendRoutes.emailVerify().path} component={EmailVerify} />

        {/* User required Token */}
        <Route exact path={frontendRoutes.chooseCommunity().path} component={ChooseCommunity} />
        <Route exact path={frontendRoutes.searchCommunity().path} component={SearchCommunity} />
        <Route exact path={frontendRoutes.feedback().path} component={Feedback} />
        <Route exact path={frontendRoutes.offer().path} component={Offer} />

        <PrivateRoute exact path={frontendRoutes.surveyQuestions().path} component={SurveyQuestions} />
        <PrivateRoute exact path={frontendRoutes.previousTours().path} component={PreviousTours} />
        <PrivateRoute exact path={frontendRoutes.upcomingTours().path} component={UpcomingTours} />
        <PrivateRoute exact path={frontendRoutes.preferTour().path} component={TourPrefereces} />
        <PrivateRoute path={frontendRoutes.cancelTour().path} component={TourCancellation} />
        <PrivateRoute exact path={frontendRoutes.confirmYourself().path} component={TourHome} />
        <PrivateRoute
          exact
          path={frontendRoutes.selectTourDate().path}
          component={SelectTourDate}
        />
        <PrivateRoute
          exact
          path={frontendRoutes.editProfile().path}
          component={EditProfile}
        />
        <PrivateRoute
          exact
          path={frontendRoutes.shareLocation().path}
          component={ShareLocation}
        />
        <PrivateRoute exact path={frontendRoutes.LockCodeScreen().path} component={LockScreen} />
        <PrivateRoute
          exact
          path={frontendRoutes.ConfirmTourScreen().path}
          component={ConfirmScreen}
        />
      </Switch>
    </>
  );
};

export default App;
