import { useHistory, useParams } from 'react-router-dom';
import { frontendRoutes } from '../../../Constants/constants';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from 'react';
import { addStaticToken, getBuilderId } from "../../../redux/action/action";
import Footer from '../../Footer/Footer';
import { Button } from '../../Button/Button';
import { useContentSettings } from '../../../hooks/useContentSettings';

type StepCardProp = {
    index: number
    title: string 
    detail: string
}

const StepCard = ({ index, title, detail }: StepCardProp) => {
    return (
        <div className='card-body'>
            <div className='card-heading d-flex align-items-center gap-3'>
                <Button style={{ boxShadow: '2px 6px 20px 2px rgba(0, 0, 0, 0.32)' }} className='button'>{index + 1}</Button>
                <h1 className='heading'>{title}</h1>
            </div>
            <p className='description'>{detail}</p>
        </div>
    )
}

const HomePage = () => {
  
    const params = useParams<{ builder_name: string }>();
    const history = useHistory();
    const dispatch = useDispatch()

    const builderId = useSelector((state: any) => state.builder.builder_id);
    const t = useContentSettings();

    const tourSteps = useMemo(() => {
        return [
            {
                title: t('step_1_title'),
                detail: t('step_1_description')
            },
            {
                title: t('step_2_title'),
                detail: t('step_2_description')
            },
            {
                title: t('step_3_title'),
                detail: t('step_3_description')
            },
            {
                title: t('step_4_title'),
                detail: t('step_4_description')
            },
        ]
    }, [t]);

    useEffect(() => {
        dispatch(addStaticToken());
    }, [dispatch])

    useEffect(() => {
        localStorage.setItem('builder_name', params.builder_name);

        !builderId && dispatch(getBuilderId());
      }, [dispatch, builderId, params.builder_name]);

    // useEffect(() => {
    //     builderError && history.push('/')
    // }, [builderError, history])
      
    return (
        <>
            <section className="home-page-screen">
                <div className="container mt-md-3">
                    <div className='banner'>
                        <h1>{t('self_tour_scheduling_title')}<br/>{t('self_tour_scheduling_subtitle')}</h1>
                        <p>{t('self_tour_scheduling_description')}</p>
                    </div>

                    <div className='cards-container'>
                        <h1 className='heading text-center'>{t('how_it_works_title')}</h1>
                        <p className='detail text-center mt-md-3 mt-2'></p>

                        <div className='cards-box'>
                            {tourSteps.map(({title, detail}, index) => 
                            <StepCard key={index} index={index} title={title} detail={detail} />
                            )}
                        </div>
                        <Button 
                        style={{ boxShadow: '2px 6px 20px 2px rgba(0, 0, 0, 0.32)' }} className='button mx-auto d-block text-uppercase mb-4'
                        onClick={() => history.push(frontendRoutes.chooseTour(params.builder_name).path)}
                        >
                            {t('start_touring_button')}
                        </Button>

                    </div>
                </div> 
                <Footer />
                <hr className='mb-0' />
                <div className='footer container d-flex align-items-center gap-3 py-1'>
                    <div className='d-flex align-items-center gap-3'>
                    <p className='m-0'><a  href='https://nternow.com/privacy-policy/' target='_blank' rel='noopener noreferrer' className='text-secondary'>Privacy Policy</a></p>
                    <hr />
                    <p className='m-0'><a href='https://nternow.com/privacy-policy/' target='_blank' rel='noopener noreferrer' className='text-secondary'>Terms</a></p>

                    </div>
                </div>
            </section>
        </>
    )
}

export default HomePage