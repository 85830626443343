import React from 'react';
import { useSelector } from 'react-redux';
import { BuilderSettings } from '../../redux/types/builders';

type Props = {
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: (e: React.SyntheticEvent) => void;
};

export const Button = (props: Props) => {
  const { children, type, className, disabled, onClick, style } = props;
  const builderSettings: BuilderSettings = useSelector((state: any) => state.builder.settings);
  const builderStyles = {
    background: builderSettings.logo ? builderSettings.primary_color : '',
    boxShadow: '2px 6px 20px 2px rgba(0, 0, 0, 0.42)',
    ...style,
  };

  return (
    <button
      type={type ? type : 'button'}
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={builderStyles}
    >
      {children}
    </button>
  );
};
