import { combineReducers } from 'redux';

import { communityReducer } from './communityReducer';
import { searchCommunityReducer } from './searchCommunityReducer';
import { tourDateReducer } from './tourDateReducer';
import { getPropertyReducer } from './getPropertyReducer';
import { phoneNumberReducer } from './phoneNumberReducer';
import { registerUserReducer } from './registerUserReducer';
import { scheduledVisitsReducer } from './scheduledVisitsReducer';
import UserDetailsReducer from './userReducer';
import personaDataReducer from './personaDataReducer';
import builderReducer from './builderReducer';
import { surveyQuestionReducer } from './surveyQuestionReducer';
import { scheduleToursReducer } from './scheduleToursReducer';
import { completedToursReducer } from './completedToursReducer';


const reducer = combineReducers({
  register: registerUserReducer,
  user: UserDetailsReducer,
  community: communityReducer,
  searchCommunity: searchCommunityReducer,
  property: getPropertyReducer,
  survey: surveyQuestionReducer,
  tour: tourDateReducer,
  scheduledVisit: scheduledVisitsReducer,
  phNumber: phoneNumberReducer,
  fields: personaDataReducer,
  builder: builderReducer,
  scheduleTours: scheduleToursReducer,
  completedTours: completedToursReducer
});

export const rootReducer = (state: any, action: any) => {
  return reducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
