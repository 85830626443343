import {
  CHOOSE_COMMUNITY_FAIL,
  CHOOSE_COMMUNITY_REQUEST,
  CHOOSE_COMMUNITY_SUCCESS,
} from "../types/actionTypes";

export const communityReducer = (state = {}, action: any) => {
  switch (action.type) {
    case CHOOSE_COMMUNITY_REQUEST:
      return { ...state, loading: true };
    case CHOOSE_COMMUNITY_SUCCESS:
      return { loading: false, community: action.payload };
    case CHOOSE_COMMUNITY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
