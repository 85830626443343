import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer from '../../Footer/Footer';
import defaultLogo from '../../../assets/images/default-logo.svg';
import { selectBuilderSettings } from '../../../redux/selectors/builders';
import { frontendRoutes } from '../../../Constants/constants';

const TourHome = () => {
  const history = useHistory();
  let params = useParams<{ builder_name?: string }>();
  const propertyId = useSelector((state: any) => state.property.property.id)
  const previousPath = () => {
    history.goBack();
  };

  const builderSettings = useSelector(selectBuilderSettings);

  return (
    <section className="main-section tour-screen d-flex justify-content-center">
      <div className="box">
        <div className="main-title">
          <figure>
            <img src={builderSettings.logo || defaultLogo} alt="Fairfield" />
          </figure>
        </div>
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <div className="box-data d-flex flex-column">
          <h1>Are you or anyone else touring the home experiencing flu-like symptoms?</h1>
          <div className="box-footer mt-auto d-flex justify-content-between">
            <Link to="#" className="button w-100">
              Yes
            </Link>
            <Link
              to={frontendRoutes.selectTourDate(params.builder_name, propertyId).path}
              className="button w-100"
            >
              No
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default TourHome;
