import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { useHistory, useParams } from 'react-router-dom';

import { useEffect } from 'react';
import { frontendRoutes } from '../../../Constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { addStaticToken, getBuilderId } from '../../../redux/action/action';
import { Button } from '../../Button/Button';
import { BuilderSettings } from '../../../redux/types/builders';
import { useContentSettings } from '../../../hooks/useContentSettings';

export const ChooseTour = () => {
  const params = useParams<{ builder_name: string }>();
  const dispatch = useDispatch();
  const t = useContentSettings();
  const history = useHistory();
  const builderId = useSelector((state: any) => state.builder.builder_id);
  const builderSettings: BuilderSettings = useSelector((state: any) => state.builder.settings);
  const isBuilderSettings = Object.keys(builderSettings).length > 0;

  useEffect(() => {
    if (builderId) {
      return;
    }

    localStorage.setItem('builder_name', params.builder_name);

    dispatch(addStaticToken());
    dispatch(getBuilderId());
  }, [builderId, dispatch, params.builder_name]);

  return (
    <section className="main-section community-screen d-flex  justify-content-center">
      <div className="box">
        <Header />
        <span
          onClick={() => {
            history.goBack();
          }}
          className="link-back icn icn-back-arrow"
        ></span>
        <h1 className="title" style={{ marginBottom: '50px' }}>
          {t('self_tours_own_time_title')}
        </h1>
        <div className="box-data d-flex flex-column position-relative">
          <div className="d-flex align-items-center">
            <span
              className="custom-item"
              style={{
                background: isBuilderSettings ? builderSettings.primary_color : '',
                flexShrink: 0,
              }}
            >
              1
            </span>
            <div>
              <p className="sub-title">{t('tour_now_title')}</p>
              <p className="">
                {t('tour_now_description')}
              </p>
            </div>
          </div>
          <Button
            className={`button w-100 mt-4`}
            style={{ marginBottom: '90px' }}
            onClick={() => {
              history.push(frontendRoutes.enterProperty(params.builder_name).path);
            }}
          >
            <span>Tour Now</span>
          </Button>
          <div className="d-flex align-items-center">
            <span
              className="custom-item"
              style={{
                background: isBuilderSettings ? builderSettings.primary_color : '',
                flexShrink: 0,
              }}
            >
              2
            </span>
            <div>
              <p className="sub-title">{t('schedule_tour_title')}</p>
              <p className="">
                {t('schedule_tour_description')}
              </p>
            </div>
          </div>
          <Button
            className={`button w-100 mt-4`}
            onClick={() => {
              history.push(frontendRoutes.chooseCommunity(params.builder_name).path);
            }}
          >
            <span>{t('schedule_tour_button')}</span>
          </Button>
        </div>
        <Footer />
      </div>
    </section>
  );
};
