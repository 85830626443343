export interface BuilderContentSettings {
  self_tour_scheduling_title: string;
  self_tour_scheduling_subtitle: string;
  self_tour_scheduling_description: string;
  how_it_works_title: string;
  step_1_title: string;
  step_1_description: string;
  step_2_title: string;
  step_2_description: string;
  step_3_title: string;
  step_3_description: string;
  step_4_title: string;
  step_4_description: string;
  start_touring_button: string;
  self_tours_own_time_title: string;
  tour_now_title: string;
  tour_now_description: string;
  tour_now_button: string;
  schedule_tour_title: string;
  schedule_tour_description: string;
  schedule_tour_button: string;
}

export const CONTENT_SETTINGS_DEFAULT: BuilderContentSettings = {
  self_tour_scheduling_title: "Self-tour scheduling",
  self_tour_scheduling_subtitle: "made easy!",
  self_tour_scheduling_description: "Finding your dream home is just a few steps away. Enjoy a private tour of our beautiful new homes at a time that is convenient for you.",
  how_it_works_title: "How it works",
  step_1_title: "Select the home",
  step_1_description: "Search our completed homes to see which offer self-guided tours.",
  step_2_title: "Verify identity",
  step_2_description: "Create an account with a valid ID and phone number.",
  step_3_title: "Choose the time",
  step_3_description: "Select ‘Tour Now’ or schedule for a later time.",
  step_4_title: "Receive access code",
  step_4_description: "At the selected time, receive an access code and enjoy your tour.",
  start_touring_button: "Start Touring",
  self_tours_own_time_title: "Self-Tours on own time",
  tour_now_title: "Tour Now! Get On-Demand Access",
  tour_now_description: "Once you are at the property, verify your identity, get a one-time use access code and enjoy your home tour!",
  tour_now_button: "Tour Now",
  schedule_tour_title: "Not at the property? Schedule a Tour!",
  schedule_tour_description: "Pick a community and check out the properties open for touring. Book a self-guided tour for a later date!",
  schedule_tour_button: "Schedule a Tour"
};
