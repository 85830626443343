import React from 'react'
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
import { useHistory } from 'react-router-dom';

type tabs = {
    name: string,
    url: string
}

type CustomTabsProps = {
    activeTab: string,
    allTabs: tabs[]
}

const CustomTabs = ({ activeTab, allTabs }: CustomTabsProps) => {

const history = useHistory();

  return (
    <div
      className="custom-tabs mb-3"
    >
      {allTabs.map((tab, index) =>
        <p className={`tab ${activeTab === tab.name && 'active'}`} key={index} onClick={() => history.push(tab.url)} >
            {tab.name}
      </p>
      )}
    </div>
  )
}

export default CustomTabs