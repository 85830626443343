import { GET_COMPLETED_TOURS_REQUEST, GET_COMPLETED_TOURS_SUCCESS, GET_COMPLETED_TOURS_FAIL } from '../types/actionTypes';

export const completedToursReducer = (state = {}, action: any) => {
  switch (action.type) {
    case GET_COMPLETED_TOURS_REQUEST:
      return { ...state, loading: true };
    case GET_COMPLETED_TOURS_SUCCESS:
      return { loading: false, completedTours: action.payload };
    case GET_COMPLETED_TOURS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
