import { useHistory, useLocation, useParams } from 'react-router-dom';
import { USER_TOKEN_KEY, frontendRoutes } from '../../../Constants/constants';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { getBuilderId, getCompletedTours } from "../../../redux/action/action";
import Header from '../../Header/Header';
import CustomTabs from '../../Tabs/Tabs';
import TourCard from '../../TourCard/TourCard';
import Footer from '../../Footer/Footer';
import { scheduledToursResponse } from './UpcomingTours';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import jwt_decode from 'jwt-decode';
import ReactPaginate from 'react-paginate';

interface tokenPayload {
  customerId: number;
  username: string;
  iat: number;
  exp: number;
}

const PreviousTours = () => {
    const token = localStorage.getItem(USER_TOKEN_KEY);
  
    const params = useParams<{ builder_name?: string }>();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation()

    const builderId = useSelector((state: any) => state.builder.builder_id);
    const loadingTours = useSelector((state: any) => state.completedTours.loading);
    const completedTours = useSelector((state: any) => state.completedTours.completedTours);

    const [page, setPage] = useState(0)

    const tabs = [
      {
        name: 'upcoming',
        url: frontendRoutes.upcomingTours(params.builder_name).path
      },
      {
        name: 'previous',
        url: frontendRoutes.previousTours(params.builder_name).path
      }
    ]

    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const page: any = queryParams.get('page') || 1
      setPage(page)
    }, [])
    
    useEffect(() => {
        !builderId && dispatch(getBuilderId());
    }, [builderId, dispatch])

    useEffect(() => {
      page !== 0 && getVisits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getVisits = () => {
      if (token) {
        let decodedToken: tokenPayload = jwt_decode(token);
        dispatch(getCompletedTours(decodedToken.customerId, page));
      }
    }

    const handlePageClick = (page: any) => {
      setPage(page.selected + 1)
      history.push(`${location.pathname}?page=${page.selected + 1}`)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

  return (
    <section className="main-section previous-tours-screen d-flex justify-content-center">
      <div className="box">
        <Header />
        <div className="main-title">
          <h1>My Tours</h1>
        </div>
        <span className="link-back icn icn-back-arrow" onClick={() => history.goBack()}></span>

        <div className="box-data d-flex flex-column mt-4">
          <CustomTabs 
            activeTab={tabs[1].name}
            allTabs={tabs} 
          />
          {loadingTours ? <LoadingSpinner /> : <div className='tour-cards-box pt-3'>
            {completedTours?.data?.length ? completedTours?.data?.map((data: scheduledToursResponse, index: number) => 
            <TourCard 
              key={index}
              tour={data} 
            >
              {/* <div className='border-top py-3 mt-3'>
                <Button type='button' className='button w-100'>Tour Again</Button>
            </div> */}
            </TourCard>
            ) : <p className='mt-3 text-center'>No Previous Tours</p>}
          </div>}

          {completedTours?.count > 10 &&
            <div className='pagination mt-5'>
              <ReactPaginate
                breakLabel="..."
                nextLabel="&#10095;"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={completedTours?.count / 10}
                forcePage={page - 1}
                previousLabel="&#10094;"
                renderOnZeroPageCount={null}
              />
          </div>
          }
        </div>
        <Footer />
      </div>
    </section>
  )
}

export default PreviousTours