import {
  BUILDER_SETTINGS_FAIL,
  BUILDER_SETTINGS_REQUEST,
  BUILDER_SETTINGS_SUCCESS,
  GET_BUILDER_ID_FAIL,
  GET_BUILDER_ID_REQUEST,
  GET_BUILDER_RENTAL_SUCCESS,
  GET_BUILDER_ID_SUCCESS,
  ADD_UTM_SOURCE,
} from '../types/actionTypes';
import { BuilderSettingsState } from '../types/builders';

const initialState: BuilderSettingsState = {
  isRental: false,
  loading: true,
  loadingBuilderSettings: true,
  settings: {
    builderName: '',
    primary_color: '',
    secondary_color: '',
    country: '',
    logo: '',
    selfie_verification_active: false,
    high_security_verification_active: false,
  },
  error: null,
  utmSource: "Webflow"
};

export default function builderReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_BUILDER_ID_REQUEST:
      return { ...state, loading: true };
    case GET_BUILDER_ID_SUCCESS:
      return { ...state, loading: false, builder_id: action.payload };
    case GET_BUILDER_RENTAL_SUCCESS:
      return { ...state, loading: false, isRental: action.payload };
    case GET_BUILDER_ID_FAIL:
      return { ...state, loading: false, error: action.payload };
    case BUILDER_SETTINGS_REQUEST:
      return { ...state, loadingBuilderSettings: true };
    case BUILDER_SETTINGS_SUCCESS:
      return { ...state, loadingBuilderSettings: false, settings: action.payload };
    case BUILDER_SETTINGS_FAIL:
      return { ...state, loadingBuilderSettings: false, error: action.payload };
    case ADD_UTM_SOURCE:
      return { ...state, utmSource: action.payload };
    default:
      return state;
  }
}
