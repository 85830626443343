import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { USER_TOKEN_KEY, backendRoutes, frontendRoutes } from '../../../Constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getBuilderId, getSurveyQuestions } from '../../../redux/action/action';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Footer from '../../Footer/Footer';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { Form } from 'react-bootstrap';
import { Button } from '../../Button/Button';
import { BuilderSettings } from '../../../redux/types/builders';

type SaveChoiceType = {
  question: number;
  choice?: number;
  answer_text: string;
  customer: string;
};

const SurveyQuestions = () => {
  const params = useParams<{ builder_name?: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const builderId = useSelector((state: any) => state.builder.builder_id);
  const builderSettings: BuilderSettings = useSelector((state: any) => state.builder.settings);
  const surveyQuestions = useSelector((state: any) => state.survey.surveyQuestions);
  const loadingQuestions = useSelector((state: any) => state.survey.loading);
  const questions = surveyQuestions?.filter((ques: any) => ques.choices);

  const [questionIndex, setQuestionIndex] = useState(0);
  const [customerId, setCustomerId] = useState('');
  const [saveChoice, setSaveChoice] = useState<SaveChoiceType[]>([]);
  const [isSavingChoices, setIsSavingChoices] = useState(false);
  const [questionAnswer, setQuestionAnswer] = useState('');
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    const token = localStorage.getItem(USER_TOKEN_KEY);

    if (token) {
      let decodedToken: any = jwt_decode(token);
      setCustomerId(decodedToken.customerId);
    }
  }, []);

  useEffect(() => {
    !builderId && dispatch(getBuilderId());
    builderId && dispatch(getSurveyQuestions(builderId));
  }, [builderId, dispatch]);

  useEffect(() => {
    isSavingChoices && onSaveChoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavingChoices]);

  const handleChoices = (question: any, choice: any) => {
    const payload: SaveChoiceType = {
      question: question.questionId,
      choice: choice.choice_id ?? 0,
      answer_text: choice.text,
      customer: customerId,
    };
    const filteredChoices = saveChoice.filter(choice => choice.question !== question.questionId);
    setSaveChoice([...filteredChoices, payload]);

    if (questionIndex < questions?.length - 1) {
      setQuestionIndex(prev => ++prev);
    } else {
      setIsSavingChoices(true);
    }
  };

  const onSaveChoices = async () => {
    const resp = await axios.post(`${backendRoutes.MOBILE_BASE_URL}survey/saveAnswer`, saveChoice);
    if (resp?.data?.status) {
      setIsSavingChoices(false);
      history.push(frontendRoutes.preferTour(params.builder_name).path);
    }
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const isNumber = !Number.isNaN(Number(value));

    if (name === 'number' && !isNumber && value.length > 0) {
      return;
    }

    setQuestionAnswer(value);
  };

  const handleNextQuestion = (question: any) => {
    if (!questionAnswer) {
      setValidationError('Required field');
      return;
    }

    setValidationError('');
    handleChoices(question, { text: questionAnswer });
    setQuestionAnswer('');
    setQuestionIndex(questionIndex + 1);
  };

  return (
    <section className="main-section customer-survey-screen d-flex justify-content-center">
      <div className="box">
        <div className="main-title">
          <h1>Customer Survey</h1>
        </div>
        <span
          className="link-back icn icn-back-arrow"
          onClick={() => {
            setQuestionIndex(questionIndex - 1);
            setValidationError('');
          }}
        ></span>
        <div className="box-data d-flex flex-column">
          <h2 className="text-center mb-3">Thank you.</h2>
          <p className="text-center">
            Before you enter please take moment to answer the questions.
          </p>

          <div className="survey-questions">
            {loadingQuestions ? (
              <LoadingSpinner />
            ) : (
              questions?.map((question: any, index: number) => {
                const questionsWithChoices = question.type === 1 || question.type === 2;
                return (
                  questionIndex === index && (
                    <div className="survey-question-box" key={index}>
                      <h4 className="survey-question text-center">{question?.text}</h4>
                      <div className="options-box">
                        {questionsWithChoices &&
                          question?.choices?.map((option: any, idx: any) => {
                            return (
                              <p
                                key={idx}
                                className="option-block"
                                style={{ backgroundColor: builderSettings.primary_color }}
                                onClick={() => handleChoices(question, option)}
                              >
                                {option.text}
                              </p>
                            );
                          })}
                        {question.type === 3 && (
                          <>
                            <p
                              key={question.id}
                              className="option-block"
                              style={{ backgroundColor: builderSettings.primary_color }}
                              onClick={() => handleChoices(question, { text: 'Yes' })}
                            >
                              Yes
                            </p>
                            <p
                              key={question.id}
                              className="option-block"
                              style={{ backgroundColor: builderSettings.primary_color }}
                              onClick={() => handleChoices(question, { text: 'No' })}
                            >
                              No
                            </p>
                          </>
                        )}
                      </div>
                      {question.type === 4 && (
                        <>
                          <Form>
                            <Form.Group controlId="text-question">
                              <Form.Control
                                style={{
                                  width: '100%',
                                  resize: 'none',
                                }}
                                as="textarea"
                                type="textarea"
                                autoFocus
                                maxLength={30}
                                placeholder="Text Stream (Limit 30 characters)"
                                name="text"
                                value={questionAnswer ?? ''}
                                onChange={handleQuestionChange}
                                isInvalid={Boolean(validationError)}
                              />
                              {validationError && (
                                <span className="invalid-feedback">{validationError}</span>
                              )}
                            </Form.Group>
                          </Form>
                          <Button
                            className="button w-100"
                            type="button"
                            style={{ marginTop: '30px' }}
                            onClick={() => {
                              handleNextQuestion(question);
                            }}
                          >
                            Continue
                          </Button>
                        </>
                      )}
                      {question.type === 5 && (
                        <>
                          <Form>
                            <Form.Group controlId="number-question">
                              <Form.Control
                                style={{ width: '100%' }}
                                type="text"
                                placeholder="Enter Number"
                                name="number"
                                value={questionAnswer ?? ''}
                                onChange={handleQuestionChange}
                                isInvalid={Boolean(validationError)}
                              />
                              {validationError && (
                                <span className="invalid-feedback">{validationError}</span>
                              )}
                            </Form.Group>
                          </Form>
                          <Button
                            className="button w-100"
                            type="button"
                            style={{ marginTop: '30px' }}
                            onClick={() => {
                              handleNextQuestion(question);
                            }}
                          >
                            Continue
                          </Button>
                        </>
                      )}
                    </div>
                  )
                );
              })
            )}
          </div>
        </div>
        <Footer />

        {isSavingChoices && (
          <div className="options-box-loader">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </section>
  );
};

export default SurveyQuestions;
