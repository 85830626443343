import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Link, useHistory, useParams } from 'react-router-dom';

import communityImage from '../../../assets/images/community-image-1.jpg';
import React, { useEffect, useState } from 'react';
import { currentDate, frontendRoutes } from '../../../Constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPropertyId,
  addStaticToken,
  getBuilderId,
  get_property_details,
  tourDate,
} from '../../../redux/action/action';
import { Button } from '../../Button/Button';
import { Form } from 'react-bootstrap';
import { checkPropertyBuilder, getPropertyInfo } from '../../../Constants/api';
import { ReactComponent as BackIcon } from '../../../assets/images/icn-arrow-prev.svg';
import { Disclosure } from '../../Disclosure/Disclosure';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { enterPropertyValidation } from '../../../Constants/validations';
import { BuilderSettings } from '../../../redux/types/builders';

export const EnterProperty = () => {
  const params = useParams<{ builder_name: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const builderId = useSelector((state: any) => state.builder.builder_id);
  const propertyBuilder = useSelector((state: any) => state?.property?.property?.builder);
  const [communityId, setCommunityId] = useState('');
  const [propertyError, setPropertyError] = useState<string | null>(null);
  const [nextPage, setNextPage] = useState(false);
  const builderSettings: BuilderSettings = useSelector((state: any) => state.builder.settings);

  const formik = useFormik<FormikValues>({
    validateOnChange: false,
    initialValues: {
      propertyId: '',
      privacyPolicy: false,
      customDisclosureActive: false,
      customDisclosure: false,
    },
    validationSchema: enterPropertyValidation,
    onSubmit: values => {
      return checkProperty(values.propertyId);
    },
  });

  useEffect(() => {
    if (builderId) {
      return;
    }

    localStorage.setItem('builder_name', params.builder_name);

    dispatch(addStaticToken());
    dispatch(getBuilderId());
  }, [builderId, dispatch, params.builder_name]);

  useEffect(() => {
    if (!nextPage) {
      return;
    }

    const isDemoMode = propertyBuilder?.toLowerCase() === 'demo mode';

    isDemoMode
      ? history.push(frontendRoutes.LockCodeScreen(params.builder_name).path)
      : history.push(frontendRoutes.shareLocation(params.builder_name).path);

    setNextPage(false);
  }, [history, nextPage, params.builder_name, propertyBuilder]);

  const checkProperty = async (propertyId: string) => {
    setCommunityId('');

    const propertyBuilderId = await checkPropertyBuilder(Number(propertyId));

    if (propertyBuilderId !== builderId) {
      setPropertyError(`Property ${propertyId} is not found`);
      return;
    }

    const property = await getPropertyInfo(propertyId);

    if (property?.community_id) {
      setCommunityId(property?.community_id);
    }

    const isPropertyVisitable =
      (property?.propStatus === 'PROPERTY_AVAILABLE' && property?.lockStatus === 'LOCK_ACTIVE') ||
      (property?.propStatus === 'PROPERTY_AVAILABLE' && property?.hub_serial_number) ||
      (property?.propStatus === 'PROPERTY_AVAILABLE' && property?.smart_lock_serial_number);

    if (!isPropertyVisitable) {
      if (
        property?.lockStatus !== 'LOCK_ACTIVE' &&
        !property?.hub_serial_number &&
        !property?.smart_lock_serial_number
      ) {
        setPropertyError(
          "We're sorry there is an issue getting access. There is no active lock on the property. Please call live support or try another property that is enabled for tours.",
        );
        return;
      }

      setPropertyError(
        `We're sorry there is an issue getting access.
        ${property?.propStatusDescription}.
        Please call live support or try another property that is enabled for tours.`,
      );
      return;
    }

    dispatch(get_property_details(property));
    dispatch(addPropertyId(propertyId));
    setPropertyError(null);

    const nowDate = 'Today ' + currentDate;
    const data = {
      showDate: nowDate,
      radioTime: 'now',
      customTime: new Date().toLocaleTimeString('en-GU', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }),
    };

    dispatch(tourDate(data));
    setNextPage(true);
  };

  if (propertyError) {
    return (
      <section className="main-section location-screen d-flex justify-content-center">
        <div className="box">
          <div className="main-title"></div>
          <span
            onClick={() => {
              setPropertyError(null);
              setNextPage(false);
              formik.setFieldValue('propertyId', '');
            }}
            className="link-back icn icn-back-arrow"
          ></span>
          <div className="box-data d-flex flex-column text-center">
            <i className={`icn ${true ? 'icn-error-pin' : 'icn-pin'}`}></i>
            <h1 className="h2">Uh-Oh</h1>
            <div className="error-message-container">
              <p className="m-0">
                {propertyError + ' '}
                {communityId && (
                  <Link to={frontendRoutes.community(params.builder_name, communityId).path}>
                    Explore other available properties
                  </Link>
                )}
              </p>
            </div>
            <Button
              onClick={() => {
                setPropertyError(null);
                setNextPage(false);
                formik.setFieldValue('propertyId', '');
              }}
              className="button back-button mb-5"
              style={{ marginTop: '50px' }}
            >
              <BackIcon />
              Try Again
            </Button>
            <Button
              onClick={() => {
                history.push(frontendRoutes.chooseCommunity(params.builder_name).path);
              }}
              className="button back-button mb-5"
            >
              Find other properties
            </Button>
            <a
              href="tel:+844-531-1283"
              className="need-help-link d-flex justify-content-center gap-1 mt-3"
              target="_blank"
              rel="noreferrer"
            >
              Need Help? <i className="icn icn-phone"></i>
            </a>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="main-section community-screen d-flex  justify-content-center">
      <div className="box">
        <Header />
        <span
          onClick={() => {
            history.goBack();
          }}
          className="link-back icn icn-back-arrow"
        ></span>
        <div className="box-data d-flex flex-column position-relative">
          <div className="mt-auto mb-auto">
            <h1 className="title" style={{ margin: '0 0 95px 0' }}>
              Enter Property ID
            </h1>
            <figure className="text-center community-image">
              <img src={communityImage} alt="Community" />
            </figure>
          </div>
          <Form
            onSubmit={formik.handleSubmit}
            style={{ marginTop: '20px' }}
          >
            <FormikProvider value={formik}>
              <Form.Group controlId="propertyId">
                <Form.Control
                  type="text"
                  placeholder="Enter the 5 digit property ID on the door"
                  name="propertyId"
                  onChange={formik.handleChange}
                  value={formik.values.propertyId}
                />
                {formik.errors.propertyId ? (
                  <span className="invalid-feedback d-block">{formik.errors.propertyId}</span>
                ) : (
                  ''
                )}
              </Form.Group>
              <div className="box-footer mt-auto">
                <Disclosure builderSettings={builderSettings} formik={formik} />
                <Button
                  type="submit"
                  className={`button w-100`}
                  style={{ lineHeight: 1.4, padding: '10px' }}
                >
                  Continue
                </Button>
              </div>
            </FormikProvider>
          </Form>
        </div>
        <Footer />
      </div>
    </section>
  );
};
